<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
        afterIcon="search"
      />
    </div>
    <yandex-map
      v-if="isShow && isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      @dblclick="onMapDblClick"
      :map-type="mapType"
      :options="mapOptions"
      :behaviors="['drag', 'scrollZoom', 'multiTouch']"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
      :balloon-component="balloonComponent"
    >
      <slot />
    </yandex-map>

    <div
      v-show="control"
      class="r-map__controls flex flex-direction-column"
    >
      <r-button-simple
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
        type="light"
        size="larishae"
        title=""
      />
      <drop-down-map-button
        v-if="showType"
        class="mb-4"
        :items="mapViewTypes"
        @select="onChangeView"
      />
      <r-button-simple
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
        title=""
      />
    </div>
  </div>
</template>

<script>

import DropDownMapButton from './DropDownMapButton.vue';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';

export default {
  name: 'PolygonMap',
  components: {
    DropDownMapButton
  },
  props: {
    balloonComponent: {
      type: Object,
      default: () => ({})
    },
    polygon: {
      type: Array,
      default: () => []
    },
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    drawPolygon: {
      type: Boolean,
      default: false
    },
    control: {
      type: Boolean,
      default: true
    },
    controls: {
      type: Array,
      default: () => []
    },
    showType: {
      type: Boolean,
      default: false
    },
    showTraffic: {
      type: Boolean,
      default: false
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    ruler: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 10
    },

    dblclickTimeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mapViewTypes: [
        { id: 'map', active: true, value: 'Схема' },
        { id: 'satellite', active: false, value: 'Спутник' },
        { id: 'hybrid', active: false, value: 'Гибрид' }
      ],
      mapType: 'map',
      isShow: true,
      mapControls: this.controls, // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        // Название свойства = название кластера (к которому будет применен данный стиль)
        main: {
          //   clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: false,
          clusterBalloonContentLayout: 'cluster#balloonCarousel',
          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]

          //   Шрифт кластера
          //   clusterIconContentLayout:
          //     '<p style="color: #FFFFFF; font-weight: bold;font-size: 24px; position: absolute;">$[properties.geoObjects.length]</p>'
        },
        orgs: {
          clusterOpenBalloonOnClick: false,
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        }
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true,
      isYmapsReady: false,
      addressObject: {},
      yaMapPoint: null,
      yaMapPointEdge: null,
      rulerSelect: false
    };
  },
  watch: {
    showTraffic(val) {
      const isTrafficShown = !!this.mapInstanse.controls.get('trafficControl').isTrafficShown();

      // если не включен контрол трафика
      if (!this.mapInstanse.controls.get('trafficControl')) return;

      if (val) {
        if (isTrafficShown) return;
        this.mapInstanse.controls.get('trafficControl').showTraffic();
      } else {
        this.mapInstanse.controls.get('trafficControl').hideTraffic();
      }
    }
  },
  async mounted() {
    // настройки карты
    const settings = {
      apiKey: '0f707b9a-62ed-46b5-98ea-b56b14c0fc31',
      coordorder: 'latlong'
    };

    !window.ymaps && (await loadYmap(settings));
    this.yaMapPoint = window.ymaps.templateLayoutFactory.createClass(
      "<div class='ya-map-point'></div>"
    );
    this.yaMapPointEdge = window.ymaps.templateLayoutFactory.createClass(
      "<div class='ya-map-point--edge'></div>"
    );
    this.isYmapsReady = true;
  },
  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },
  methods: {
    onRuler() {
      if (this.rulerSelect) {
        this.mapInstanse.controls.get('rulerControl').deselect();
      } else {
        this.mapInstanse.controls.get('rulerControl').select();
      }
      this.rulerSelect = !this.rulerSelect;
    },
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onChangeView(obj) {
      // Не хочет менять подложку без выключения
      this.isShow = false;
      this.mapType = obj.id;
      this.mapViewTypes = this.mapViewTypes.map(el => ({ ...el, active: el.id === obj.id }));
      this.$nextTick(() => {
        this.isShow = true;
      });
    },

    onMapInit(e) {
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.mapInstanse.options.set('openBalloonOnClick', false);
      this.mapInstanse.controls.add('rulerControl');
      this.mapInstanse.controls.get('rulerControl').enable();

      if (this.$props.drawPolygon) {
        const polygon = new ymaps.Polygon(
          this.$props.polygon,
          {},
          {

            zIndex: 999,
            draggable: true,
            // Курсор в режиме добавления новых вершин.
            editorDrawingCursor: 'crosshair',
            // Максимально допустимое количество вершин.
            editorMaxPoints: 2000,
            // Цвет заливки.
            fillColor: '#3d75e4',
            fillOpacity: 0.16,
            // Цвет обводки.
            strokeColor: '#3d75e4',
            // Ширина обводки.
            strokeWidth: 2,
            editorVertexLayout: this.yaMapPoint,
            editorVertexLayoutHover: this.yaMapPoint,
            editorVertexLayoutActive: this.yaMapPoint,
            editorVertexLayoutDrag: this.yaMapPoint,
            editorEdgeLayout: this.yaMapPointEdge,
            editorEdgeLayoutHover: this.yaMapPointEdge,
            editorEdgeLayoutActive: this.yaMapPointEdge,
            editorEdgeLayoutDrag: this.yaMapPointEdge,
            editorVertexIconShape: {
              type: 'Rectangle',
              coordinates: [
                [-4, -4],
                [4, 4]
              ]
            },
            editorEdgeIconShape: {
              type: 'Rectangle',
              coordinates: [
                [-4, -4],
                [4, 4]
              ]
            }
          }
        );
        // Добавляем многоугольник на карту.
        this.mapInstanse.geoObjects.add(polygon);

        // В режиме добавления новых вершин меняем цвет обводки многоугольника.
        const stateMonitor = new ymaps.Monitor(polygon.editor.state);

        polygon.events.add(
          'geometrychange',
          _.throttle(() => {
            this.$emit('geometryChange', polygon.geometry.getCoordinates());
          }, 500)
        );

        // Включаем режим редактирования с возможностью добавления новых вершин.
        polygon.editor.startEditing();
      }
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    async onMapClick(e) {},

    onMapDblClick(e) {
      const coords = e.get('coords');
      //   Закрываем открытый Balloon при клике по карте
      if (this.dblclickTimeout && this.timeout) clearTimeout(this.timeout);
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('dblclick', coords);
    },

    onRightClick(e) {
      this.$emit('onRightClick', e);
    },

    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);

    > * {
      background-color: #fff;
    }
  }

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}

// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}

::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}

/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Golos UI';
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}
::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep  .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}
::v-deep .ymaps-2-1-79-ruler-balloon-pane {
  .ymaps-2-1-79-balloon {
    left: -30px !important;
    top: -6px !important;
    box-shadow: unset;
    /* border-radius: 16px 16px 16px 0px; */
    overflow: visible;
    transform: translateX(60%);
  }
  .ymaps-2-1-79-balloon__layout {
    background: transparent;
  }
  .ymaps-2-1-79-balloon__content {
    background: #9954f2;
    border-radius: 16px !important;
    padding: 4px 12px !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-family: 'Golos UI' !important;
    color: #ffffff !important;
    & > * {
      height: auto !important;
      width: auto !important;
    }
  }
}
::v-deep .ymaps-2-1-79-ruler-line-pane {
  .ymaps-2-1-79-image {
    left: -4px !important;
    top: -4px !important;
    width: 8px !important;
    height: 8px !important;
    background-size: 8px 8px !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='4' cy='4' r='3' fill='white' stroke='%239954F2' stroke-width='2'/%3e%3c/svg%3e ") !important;
  }
}
</style>
