<template>
  <div>
    <div class="flex mb-6">
      <!-- <router-link
         class="mr-6"
         to="region"
         v-if="this.$store.getters.userIsRoot"
       >
         <r-button-action
           icon="add"
           title="Добавить вручную"
         />
       </router-link>-->
      <r-button-action
        transparent
        class="flex align-items-center sulguni color-rocky mr-6"
        @click.native="openUploadModal"
        title="Загрузить данные"
        icon="add"
      />
      <r-button-action
        @click.native="openHistoryModal"
        title="История загрузки"
        icon="archive"
      />
    </div>
    <div class="flex mb-6">
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            code: 'ALL',
            value: 'Все',
          },
          ...this.$store.state.regions.cadastralXMLTypes,
        ]"
        label="Название файла"
        v-model="selectedXMLTypes"
        title-value="value"
        id-value="code"
        @input="changeFilter($event, 'selectedXMLTypes')"
      />
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            id: 'ALL',
            title: 'Все',
          },
          {
            id: 'cadastralNum',
            title: 'Кадастровый номер',
          },
          {
            id: 'registrationNum',
            title: 'Регистрационный номер',
          },
        ]"
        label="Идентификатор"
        v-model="selectedNumType"
        @input="changeFilter($event, 'selectedNumType')"
      />
      <r-input
        class="flex-1 mr-6"
        label="Кадастровый номер / Идентификатор"
        v-model.trim="cadastralNum"
        v-mask="'##:##XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
      />

      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="menu"
        icon-size="20"
        @click="changeSelectedView('table')"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="changeSelectedView('map')"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div
        class="flex mb-6 align-items-center"
        v-show="!isLoading"
      >
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
        </div>
      </div>

      <scroll-table
        v-if="selectedView === 'table'"
        :list="filteredObjects.list"
        :headings="headings"
        :columns-grid="tableGridColumns"
        row-link="/region/"
        class="rows_space-between"
        start-sorted-column="cadastralNum"
        :delete-function="openDeleteModal"
      />
      <div
        v-if="selectedView === 'map'"
        class="objects__map"
        ref="map"
      >
        <rir-map-leaflet
          style="height: 648px"
          ref="mapControl"
        >
          <l-geo-json
            :geojson="filteredObjects"
            :options="options"
          />
        </rir-map-leaflet>
        <!--<polygon-map
          v-if="isShowMap"
          collapse-btn
          style="height: 648px"
          class="mb-4"
          ruler
          :balloon-component="regionBalloon"
        >
          <div
            v-for="(pol, index) in filteredObjects"
            :key="index + 'regions'"
          >
            <ymap-marker
              :marker-id="index + 'pol2'"
              marker-type="polygon"
              :coords="pol.coordinates"
              :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
              :marker-stroke="{ color: '#3d75e4', width: 2 }"
              fill-rule="nonZero"
              :balloon-component-props="{
                item: pol,
                openDeleteModal: openDeleteModal,
              }"
            />
          </div>
        </polygon-map> -->
      </div>
    </template>
  </div>
</template>

<script>
import { LGeoJson } from 'vue2-leaflet';
import { featureGroup } from 'leaflet';
import Vue from 'vue';
import RegionBalloon from './RegionBalloon.vue';
import ScrollTable from './Table';
import ModalUpload from './ModalUpload';
import { wordMatch } from '../helpers/utils';
import DeleteModal from './DeleteModal';
import Api from '../api/Api';
import Loader from './Loader';
import ModalHistory from './ModalHistory';
import RirMapLeaflet from '../components/RirMapLeaflet';
import router from '../router';

export default {
  name: 'TabRegions',
  components: {
    Loader,
    ScrollTable,
    LGeoJson,
    RirMapLeaflet
  },
  data() {
    return {
      selectedView: 'table',
      selectedXMLTypes: 'ALL',
      searchText: null,
      cadastralNum: null,
      sortTypes: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'Муниципальная собственность'
        },
        {
          id: 2,
          title: 'Неразграниченная собственность'
        }
      ],
      // table
      headings: [
        {
          title: 'Кадастровый номер',
          field: 'cadastralNum'
        },
        {
          title: 'Адрес',
          field: 'address'
        },
        {
          title: 'Площадь, м2',
          field: 'square'
        },
        {
          title: 'Стоимость, ₽',
          field: 'cost'
        },
        {
          title: 'Категория',
          field: 'category'
        },
        {
          title: 'Дата внесения в реестр',
          field: 'date'
        }
      ],
      tableGridColumns: ['190px', '216px', '120px', '160px', '172px', '205px'], // '40px'
      list: [],
      regionsFiles: [],
      timeoutId: null,
      isShowMap: false,
      selectedMarkerId: null,
      selectedNumType: 'ALL'
    };
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        const PopupCont = Vue.extend({
          router,
          template: `
  <router-link
    to="region/${feature.properties.id}"
    class="r-map-balloon pointer"
  >
   <!-- <p class="сaprino opacity-48 mb-1">
      ${feature.columns.cadastralNum.subtitle || '—'}
    </p>-->
    <p class="roquefort mb-1 color-titanic">
      ${feature.columns.cadastralNum.title}
    </p>
    <p class="сaprino opacity-72 mb-3">
      ${feature.columns.address || '—'}
    </p>
    <div class="flex align-items-center mb-4 mozzarella">
      <r-icon
        size="16"
        class="mr-2"
        icon="buildings"
        fill="rocky"
      />
      ${feature.columns.category || '—'}
    </div>
    <div class="flex align-items-center mb-4 mozzarella">
      <r-icon
        size="16"
        class="mr-2"
        icon="area"
        fill="rocky"
      />
      ${feature.columns.square}
    </div>
    <div class="flex align-items-center mozzarella">
      <r-icon
        size="16"
        class="mr-2"
        icon="finance-money"
        fill="rocky"
      />
      ${feature.columns.cadastralCost}
    </div>
    <!--  <r-button
        v-if="$store.getters.userIsRoot"
        class="color-fargo sulguni align-items-center"
        color="transparent"
        content-width
        @click="onDelete"
      >
        <r-icon  size="16"
          class="mr-2"
          icon="delete"
          fill="fargo"
        />
        Удалить
      </r-button> -->
  </router-link>`
        });
        const popup = new PopupCont();
        layer.bindPopup(popup.$mount().$el);
      };
    },
    regionBalloon() {
      return RegionBalloon;
    },
    filteredObjects() {
      let list = this.$store.getters.getRegionsForTable;

      if (this.selectedNumType === 'cadastralNum') {
        list = list.filter(el => el?.columns?.cadastralNum?.title.indexOf('-') <= 0);
      } else if (this.selectedNumType === 'registrationNum') {
        list = list.filter(el => el?.columns?.cadastralNum?.title.indexOf('-') >= 0);
      }
      if (this.selectedXMLTypes !== 'ALL') {
        list = list.filter(el => this.selectedXMLTypes === el.properties.schemaTypeCode);
      }
      if (this.cadastralNum) {
        list = list.filter(
          el => el?.columns?.cadastralNum?.title.toLowerCase().indexOf(this.cadastralNum) > -1
        );
      }
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.columns.address || '', this.searchText) <= 0.7
            || wordMatch(el?.columns?.cadastralNum?.title || '', this.searchText) <= 0.7
        );
      }
      return {
        list,
        type: 'FeatureCollection',
        features: list
      } || [];
    },
    countObjects() {
      return this.filteredObjects.list.length;
    },
    isLoading() {
      return this.$store.state.regions.regionsLoading;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    inputMask: {
      get() {
        return this.cadastralNum;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          const mask = val
            ?.replace(/\D/g, '')
            ?.replace(/(\d{2})(\d)/, '$1:$2')
            ?.replace(/(\d{2})(\d)/, '$1:$2');
          this.cadastralNum = mask;
        }, 420);
      }
    }
  },
  async created() {
    const setFiltersField = [
      'selectedXMLTypes',
      'selectedNumType'
    ];
    setFiltersField.forEach(field => {
      const initialField = this[field];
      this[field] = localStorage.getItem(field) || initialField;
    });

    await this.$store.dispatch('getRegions');
    this.isShowMap = true;
    await this.$store.dispatch('getCadastralXMLTypes');
  },
  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    changeFilter(e, field) {
      localStorage.setItem(field, e);
    },
    changeSelectedView(type) {
      this.selectedView = type;
      // if (type === 'map') {
      //   this.$nextTick().then(() => {
      //     const group = new featureGroup();
      //     console.log(this.$refs.mapControl)
      //     this.$refs.mapControl.mapObject.eachLayer(layer => {
      //       if (layer.feature != undefined) group.addLayer(layer);
      //     });
      //
      //     this.$refs.mapControl.mapObject.fitBounds(group.getBounds(), { padding: [20, 20] });
      //   });
      // }
    },
    openDeleteModal(id) {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить участок?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: () => {
          this.delete(id);
        }
      });
    },
    async delete(id) {
      await new Api().deleteRegion(id).then(() => {
        window.location.reload();
      });
    },
    openUploadModal() {
      this.$rir.modal.open(ModalUpload);
    },
    openHistoryModal() {
      this.$rir.modal.open(ModalHistory);
    }
  }
};
</script>

<style scoped lang="scss">
.rows_space-between {
  ::v-deep .table_row {
    justify-content: space-between;
  }
}

::v-deep .leaflet-container a {
  color: inherit;
}
</style>
