<template>
  <div class="objects">
    <admin-header
      title="Земельные участки КУМИ"
      :back="false"
    />
    <r-tabs
      class="mt-7 mb-6"
      :items="tabs"
      v-model="activeTabId"
    />
    <tab-contracts v-if="activeTabId.id === 'contracts'" />
    <tab-users v-if="activeTabId.id === 'users'" />
    <tab-regions v-if="activeTabId.id === 'regions'" />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import TabContracts from '../components/TabContracts';
import TabUsers from '../components/TabUsers';
import TabRegions from '../components/TabRegions';

export default {
  name: 'ObjectList',
  components: {
    AdminHeader,
    TabContracts,
    TabUsers,
    TabRegions
  },
  data() {
    return {
      activeTabId: {
        id: 'contracts',
        title: 'Договоры'
      },
      tabs: [
        {
          id: 'contracts',
          title: 'Договоры'
        },
        {
          id: 'users',
          title: 'Пользователи'
        },
        {
          id: 'regions',
          title: 'Кадастровые объекты'
        }
      ]
    };
  },
  async activated() {
    this.activeTabId = this.tabs.find(item => item.id === this.$route.query.tab) || {
      id: 'contracts',
      title: 'Договоры'
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.r-popover {
  display: flex;
  align-items: center;

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .r-button {
      display: flex;
      align-items: center;

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .r-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .r-popover__content {
  background: red;
}
</style>
