<template>
  <router-link
    :to="'region/' + item.id"
    class="r-map-balloon pa-4 pointer"
  >
    <p class="сaprino opacity-48 mb-1">
      {{ item.properties.type || '—' }}
    </p>
    <p class="roquefort mb-1 color-titanic">
      {{ item.columns.cadastralNum.title || '—' }}
    </p>
    <p class="сaprino opacity-72 mb-3">
      {{ item.columns.address || '—' }}
    </p>
    <div class="flex align-items-center mb-4 mozzarella">
      <r-icon
        size="16"
        class="mr-2"
        icon="buildings"
        fill="rocky"
      />
      {{ item.columns.category || '—' }}
    </div>
    <div class="flex align-items-center mb-4 mozzarella">
      <r-icon
        size="16"
        class="mr-2"
        icon="area"
        fill="rocky"
      />
      {{ item.columns.square || '—' }}
    </div>
    <div class="flex align-items-center mozzarella">
      <r-icon
        size="16"
        class="mr-2"
        icon="finance-money"
        fill="rocky"
      />
      {{ item.columns.cadastralCost || '—' }}
    </div>
    <!--  <r-button
        v-if="$store.getters.userIsRoot"
        class="color-fargo sulguni align-items-center"
        color="transparent"
        content-width
        @click="onDelete"
      >
        <r-icon  size="16"
          class="mr-2"
          icon="delete"
          fill="fargo"
        />
        Удалить
      </r-button> -->
  </router-link>
</template>

<script>

export default {
  name: 'RegionBalloon',
  props: {
    openDeleteModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onDelete() {
      this.$props.openDeleteModal(this.$props.item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  display: block;

  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    padding: 12px 16px 0;
  }
}

</style>
