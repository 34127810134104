<template>
  <div class="d-flex align-items-center">
    <loader v-if="loading" />
    <div v-else>
      <div class="ricotta mb-6">
        {{ data.fileName }}
      </div>
      <p
        class="sulguni mb-2"
        v-if="type === 'cadastralIdentifiersOfCreatedDuplicatesOfMapObjects'"
      >
        Создано дублей: <b>{{ data.numberOfCreatedDuplicatesOfMapObjects || '0' }}</b>
      </p>
      <div
        class="sulguni mb-2"
        v-if="type === 'cadastralIdentifiersOfFailedMapObjects'"
      >
        Неудачно: <b class="text-fargo">{{ data.numberOfFailedMapObjects || '0' }}</b>
      </div>
      <p
        class="sulguni mb-4"
        v-if="type === 'cadastralIdentifiersOfMapObjectsWithoutCoords'"
      >
        Без координат: <b>{{ data.numberOfMapObjectsWithoutCoords || '0' }}</b>
      </p>
      <p
        class="mozarella mb-1"
        v-for="num of getCadastralNumbers"
        :key="num"
      >
        {{ num }}
      </p>
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';
import Loader from './Loader';

export default {
  name: 'ModalHistoryById',
  components: { Loader },

  props: {
    id: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      data: {},
      loading: true
    };
  },
  computed: {
    getCadastralNumbers() {
      return this.data[this.$props.type]?.split(',') || '';
    }
  },
  async mounted() {
    this.loading = true;

    await new Api().getUploadHistoryById(this.$props.id).then(res => {
      this.data = res;
    }).finally(() => {
      this.loading = false;
    });
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
