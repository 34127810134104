import Api from '@/api/Api';

const api = new Api();
export default {
  state: () => ({
    users: [],
    usersLoading: true,
    userTypes: [
      {
        id: 'INDIVID',
        title: 'Индивидуальный предприниматель'
      },
      {
        id: 'PHIS',
        title: 'Физическое лицо'
      },
      {
        id: 'UR',
        title: 'Юридическое лицо'
      }
    ],
    userTypesObj: {
      INDIVID: 'Индивидуальный предприниматель',
      PHIS: 'Физическое лицо',
      UR: 'Юридическое лицо'
    }
  }),
  mutations: {
    setUsers(state, res) {
      state.users = res;
    },
    setLoading(state, bool) {
      state.usersLoading = bool;
    }
  },
  actions: {
    async getUsers(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getUsers();
      ctx.commit('setUsers', res);
      ctx.commit('setLoading', false);
      return res;
    }

  },
  getters: {
    getUsersForTable(state) {
      return state?.users?.map(el => ({
        id: el.id,
        active: el?.active || true,
        userTypeKey: el?.type,
        columns: {
          name: {
            title: el?.title,
            subtitle: state.userTypesObj[el?.type]
          },
          inn: el?.inn,
          actualAddress: el?.actualAddress,
          legalAddress: el?.legalAddress
        }
      }));
    }
  }
};
