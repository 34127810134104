<template>
  <div class="modal_upload">
    <template v-if="data && !loading">
      <p class="ricotta mb-5 text-align-center">
        Файл загружен
      </p>
      <p class="sulguni mb-2">
        Найдено существующих: <b>{{ data.numberOfAlreadyExistingMapObjects || '0' }}</b>
      </p>
      <p class="sulguni mb-2">
        Новых: <b>{{ data.numberOfCreatedNewMapObjects || '0' }}</b>
      </p>
      <p class="sulguni mb-2">
        Создано дублей: <b>{{ data.numberOfCreatedDuplicatesOfMapObjects || '0' }}</b>
      </p>
      <p class="sulguni mb-2">
        Перезаписано: <b>{{ data.numberOfUpdatedMapObjects || '0' }}</b>
      </p>
      <!--<p class="sulguni mb-2">
        Проигнорировано: <b>{{ data.ignored || '0' }}</b>
      </p>-->
      <div class="sulguni mb-2">
        Неудачно: <b class="text-fargo">{{ data.numberOfFailedMapObjects || '0' }}</b>
      </div>
      <p class="sulguni mb-2">
        Без координат: <b>{{ data.numberOfMapObjectsWithoutCoords || '0' }}</b>
      </p>
      <div class="sulguni mb-2">
        Найденные координатные системы: <b>{{ coordsSystem }}</b>
      </div>
      <r-button
        class="flex-1 mt-10"
        @click="endUpload"
        title="Завершить"
        width="wide"
      />
    </template>
    <template v-else>
      <p class="ricotta mb-5 text-align-center">
        Загрузка xml файла
      </p>
      <p class="sulguni text-align-center mb-5">
        Выясните у источника данного файла систему координат,
        из которой будет производиться конвертация расположения объектов
      </p>
      <p class="sulguni text-align-center mb-10">
        Найдите эту систему координат в выпадающем списке
      </p>
      <r-select
        :disabled="loading"
        :is-search="true"
        label="Система координат"
        class="flex-1 mb-1"
        :items="coordinatesSystemList"
        v-model="selectedCoordinatesSystem"
        return-object
        @input="changeCoordinatesSystem"
      />
      <p
        class="mozzarella anie mb-8"
        v-if="getLocalCoordsSystem"
      >
        Последняя выбранная система координат: {{ getLocalCoordsSystem }}
      </p>

      <!--   <upload-file
        v-show="!!selectedCoordinatesSystem.str"
        :accept-file="['application/xml', 'text/xml', 'application/pgp-signature']"
        :count-file="5"
        upload-url="/uploadfile/index.php"
        :value="fileUpload"
        :params-body="{ upload_url: 'kumi', renameShort: 1 }"
        :count-line="1"
        sub-title="или перетяните сюда файл xml"
        @input="loadFile"
      />
-->
      <r-uploader
        class="mt-6"
        v-model="files"
        title="Выбрать файлы"
        sub-title="или перетяните сюда файл xml"
        v-show="!!selectedCoordinatesSystem.str"
        :accept="['application/xml', 'text/xml', 'application/pgp-signature']"
        :callback="loadFile"
        :max-files="5"
        :max-size="50"
        error
        :disabled="loading"
      />
      <div
        class="mt-6"
      >
        <r-file
          v-for="(file, index) in files"
          :key="index"
          icon-preview="file"
          class="mb-3"
          :file="file"
          :title="file.name"
          :button-simple-option="{
            icon: 'delete',
            color: 'fargo',
          }"
          @actions="deleteFile"
        />
      </div>

      <div
        class="mt-8"
        v-show="fileUpload.length > 0"
      >
        <p class="text-fargo mb-3">
          {{ error }}
        </p>

        <r-button
          @click="uploadFile"
          :disabled="loading"
          width="wide"
          :title=" loading ? 'Загрузка...' : 'Загрузить'"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Api from '../api/Api';
import { formattedFiles } from '../helpers/utils';
import UploadFile from './UploadFile';

export default {
  name: 'ModalUpload',
  components: {
    UploadFile
  },
  data() {
    return {
      coordinatesSystemList: [],
      selectedCoordinatesSystem: {},
      uploadFileUrl: '',
      error: '',
      loading: false,
      data: null,
      fileUpload: [],
      files: []
    };
  },
  computed: {
    coordsSystem() {
      return this.data?.foundCoordinateSystems?.toString() || 'Нет данных';
    },
    getLocalCoordsSystem() {
      return window.localStorage.getItem('oldCoordsSystem') || false;
    }
  },
  mounted() {
    new Api().getСoordinatesSystem().then(res => {
      this.coordinatesSystemList = res.data;
    });
  },
  methods: {
    deleteFile(file) {
      this.files = this.files.filter(val => file.url !== val.url);
    },
    endUpload() {
      this.$rir.modal.close();
      /* const r = this.$router.resolve({
        name: 'index',
        query: {
          tab: 'regions'
        }
      });
      window.location.assign(r.href); */
    },
    loadFile(file) {
      return new Promise(resolve => {
        this.files.push({
          type: file.file.type,
          size: file.file.size,
          name: file.file.name,
          url: URL.createObjectURL(file.file)
        });
        this.fileUpload.push(file.file);
        resolve();
      });

      /* files = JSON.parse(files).files;
       if (!files?.length) {
         this.fileUpload = [];
         return;
       }
       if (files[0]?.error) {
         // this.$refs.message.showMessage(`${files[0]?.name} ${files[0]?.error}`, 'error');
         this.$rir.notification.send({
           title: files[0]?.error,
           seconds: 10,
           iconOptions: {
             fill: 'fargo',
             icon: 'delete'
           }
         });
       } else {
         this.fileUpload.push(...formattedFiles(files));
         this.uploadFileUrl = this.fileUpload[0].url;
       } */
    },
    changeCoordinatesSystem(item) {
      this.selectedCoordinatesSystem = item;
    },
    async uploadFile() {
      this.loading = true;
      for (const file of this.fileUpload) {
        await this.uploadRegions(file);
      }
     this.loading = false;
    },
    async uploadRegions(file) {
      return new Promise(resolve => {
        new Api().uploadRegions({
          file,
          projStr: this.selectedCoordinatesSystem.str
        }).then(async res => {
          if (res?.error) {
            this.error = res.error;
          } else {
            this.data = { ...this.data, ...res };
            await this.$store.dispatch('getRegions');
            localStorage.setItem('oldCoordsSystem', this.selectedCoordinatesSystem?.title || 'Нет данных');
          }
          resolve();
        }).catch(err => {
          this.error = err.message;
        });
      });
    }
  }
};
</script>

<style scoped>
.modal_upload {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.text-align-center {
  text-align: center;
}

.loading {
  display: inline-block;
  clip-path: inset(0 1.5ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -0.5ch 0 0)
  }
}
</style>
