<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <admin-header
      :title="!isEditPage ? 'Новый пользователь' : formData.title || 'Данные пользователя'"
      :to="{ name: 'admin', query: { tab: 'users' } }"
    >
      <sup
        class="text-c13 color-fargo"
        v-if="!isSave && isEditPage"
      >Не сохранено</sup>
    </admin-header>
    <r-tabs
      v-if="isEditPage && contractsList.length > 0"
      class="mt-7 mb-6"
      :items="[
        {
          id: 'profile',
          title: 'Профиль',
        },
        {
          id: 'contracts',
          title: 'Договоры',
        },
      ]"
      v-model="activeTabId"
    />
    <div v-show="activeTabId.id === 'profile'">
      <div class="form mt-8 flex">
        <div class="flex flex-1 flex-direction-column">
          <div class="flex">
            <div class="field_wrapper flex-1 mr-6">
              <r-select
                :class="{ field_error: $v.formData.type.$error }"
                :items="$store.state.users.userTypes"
                label="Вид деятельности"
                v-model="formData.type"
                @input="changeUserType"
              />
              <div
                class="error mozzarella color-fargo bottom"
                v-if="$v.formData.type.$error && !$v.formData.type.required"
              >
                Поле обязательно для заполнения
              </div>
            </div>
            <div class="flex-1" />
          </div>
          <div class="flex mt-10">
            <div class="field_wrapper flex-1 mr-6">
              <r-input
                :label="formData.type === 'PHIS' ? 'ФИО' : 'Наименование'"
                v-model="formData.title"
                @blur="isChange('title')"
                :class="{ field_error: $v.formData.title.$error }"
              />
              <div
                class="error mozzarella color-fargo bottom"
                v-if="$v.formData.title.$error && !$v.formData.title.required"
              >
                Поле обязательно для заполнения
              </div>
            </div>
            <div class="field_wrapper flex-1">
              <r-input
                :class="{ field_error: $v.formData.inn.$error }"
                label="ИНН"
                v-model="formData.inn"
                @blur="isChange('inn')"
              />
              <div
                class="error mozzarella color-fargo bottom"
                v-if="$v.formData.inn.$error && !$v.formData.inn.required"
              >
                Поле обязательно для заполнения
              </div>
            </div>
          </div>
          <div class="flex mt-6">
            <div class="flex align-start flex-1 mr-6">
              <div class="flex-1 mr-2">
                <r-input
                  label="Телефон"
                  @onPressEnter="addPhone"
                  v-model="phone"
                  v-mask="'+7 (###) ###-##-##'"
                />
                <selected-list
                  v-show="formData.phones.length"
                  class="mt-4"
                  icon="telephone"
                  v-model="formData.phones"
                />
              </div>
              <r-button-simple
                size="larishae"
                @click="addPhone"
                icon="add"
                :disabled="!phone"
              />
            </div>
            <div class="flex align-start flex-1">
              <div class="flex-1 mr-2">
                <r-input
                  label="Имейл"
                  @onPressEnter="addMail"
                  v-model="email"
                />
                <selected-list
                  v-show="formData.emails.length"
                  class="mt-4"
                  icon="mail"
                  v-model="formData.emails"
                />
              </div>
              <r-button-simple
                size="larishae"
                @click="addMail"
                icon="add"
                :disabled="!email"
              />
            </div>
          </div>
          <div class="flex mt-6">
            <div class="flex flex-1 mr-6">
              <r-input
                class="flex-1"
                label="Фактический адрес"
                v-model="formData.actualAddress"
                @blur="isChange('actualAddress')"
              />
            </div>
            <div class="flex flex-direction-column flex-1">
              <r-input
                v-if="formData.type !== 'PHIS'"
                label="Юридический адрес"
                @blur="isChange('legalAddress')"
                :readonly="formData.addressTheSame"
                v-model="legalAddress"
              />
              <r-checkbox
                v-if="formData.type !== 'PHIS'"
                class="mt-4"
                title="Совпадает с почтовым адресом"
                v-model="formData.addressTheSame"
              />
            </div>
          </div>
          <template v-if="formData.type !== 'PHIS'">
            <h3 class="bryndza mb-6 mt-10">
              Сотрудники
            </h3>
            <r-button-action
              icon="add"
              title="Контакты сотрудника"
              @click="addPerson"
            />
            <div
              class="field_wrapper"
              :class="{ field_error: $v.formData.type.$error }"
            >
              <div
                class="error mozzarella color-fargo bottom"
                v-if="$v.formData.persons.$error && !$v.formData.persons.required"
              >
                Добавьте хотя-бы одного сотрудника
              </div>
            </div>
            <persons-list
              v-show="formData.persons.length"
              v-model="formData.persons"
              @input="isChange('persons')"
            />
          </template>

          <!--buttons-->
          <div class="flex mt-10">
            <r-button-action
              v-if="isEditPage && contractsList.length < 1"
              icon="delete"
              color="fargo"
              title="Удалить пользователя"
              @click="onDelete"
            />
            <r-tooltip
              activator-hover
              color="amelie"
              v-if="isEditPage && contractsList.length > 0"
              title="Пользователь не может быть удалён, так как прикреплён к договорам"
            >
              <template #activator>
                <r-button-action
                  icon="delete"
                  color="fargo"
                  title="Удалить пользователя"
                />
              </template>
            </r-tooltip>
          </div>
        </div>
      </div>
      <div class="button_container">
        <r-button
          @click="submit"
          :disabled="isLoading || isSave"
          :title=" isEditPage ? 'Сохранить' : 'Добавить'"
          width="wide"
        />
      </div>
    </div>
    <div v-show="activeTabId.id === 'contracts'">
      <contracts-table :list="contractsList" />
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import SelectedList from '../components/SelectedList';
import DeleteModal from '../components/DeleteModal';
import PersonsList from '../components/PersonsList';
import ContractsTable from '../components/ContractsTable';
import Api from '../api/Api';
import { generateContractsForTable } from '../helpers/utils';

export default {
  name: 'User',
  components: { PersonsList, AdminHeader, SelectedList, ContractsTable },
  data() {
    return {
      activeTabId: {
        id: 'profile',
        title: 'Профиль'
      },
      isSave: true,
      flyButton: '',
      isLoading: false,
      initialData: {},
      email: null,
      phone: null,
      contractsList: [],
      user: {},
      formData: {
        title: null,
        type: null,
        phones: [],
        emails: [],
        actualAddress: null,
        legalAddress: null,
        addressTheSame: false,
        inn: null,
        persons: []
      }
    };
  },
  validations: {
    formData: {
      type: {
        required
      },
      title: {
        required
      },
      inn: {
        required: requiredIf(data => data.type === 'UR')
      },
      persons: {
        required: requiredIf(data => (data.type === 'UR' || data.type === 'INDIVID'))
      }
    }
  },
  computed: {
    legalAddress: {
      get() {
        if (this.formData.addressTheSame) {
          return this.formData.actualAddress;
        }
        return this.formData.legalAddress;
      },

      set(val) {
        this.formData.legalAddress = val;
      }
    },
    orgList() {
      return [];
    },
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  async activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData(this.$route.params.id);
    this.isShowMap = true;
    this.$v.$reset();
  },

  deactivated() {
    this.isShowMap = false;
    this.activeTabId = {
      id: 'profile',
      title: 'Профиль'
    };
  },
  mounted() {
    if (!this.isEditPage) {
      this.isSave = false;
      this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    }
  },
  methods: {
    changeUserType(type) {
      this.formData.persons = [];
    },
    addPerson() {
      this.formData.persons.unshift({
        id: -1,
        user_id: this.formData?.persons?.length + 1,
        delete: true,
        title: null,
        position: null,
        phone: null,
        email: null,
        phones: [],
        emails: []
      });
      this.isChange('persons');
    },
    async onArchive() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Выключить контроллер?',
        subtitle: 'Все опоры освещения, относящиеся к контроллеру будут отключены.',
        buttons: ['Не выключать', 'Да, выключить '],
        id: this.$route.params.id,
        actionPostfix: 'Controller'
      });
    },
    async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить пользователя?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    },
    async delete() {
      await new Api().deleteUser(this.$route.params.id).then(() => {
        const r = this.$router.resolve({
          name: 'index',
          query: {
            tab: 'users'
          }
        });
        window.location.assign(r.href);
      });
    },
    addPhone() {
      this.formData.phones.push(this.phone);
      this.phone = null;
      this.isChange('phones');
    },
    addMail() {
      this.formData.emails.push(this.email);
      this.email = null;
      this.isChange('emails');
    },
    async setData(id) {
      this.initialData = JSON.parse(JSON.stringify(this.formData));
      if (id) {
        await new Api().getUserById(id).then(res => {
          this.user = res;
          this.formData = { ...this.formData, ...res };
        });
        if (this.formData.persons.length) {
          const arr = [];
          for (const el of this.formData.persons) {
            new Api().getUserContracts(el.id).then(res => {
              this.contractsList.push(...generateContractsForTable(res, this.$store.state.contracts.typeOfLawObj));
              arr.push({ ...el, delete: !res.length });
            });
          }
          this.formData.persons = arr;
        } else {
          await new Api().getUserContracts(id).then(res => {
            this.contractsList.push(...generateContractsForTable(res, this.$store.state.contracts.typeOfLawObj));
          });
        }
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          id: this.isEditPage ? this.$route.params.id : -1,
          ...this.formData
        };
        if (this.formData.type === 'PHIS') {
          data.persons = [
            {
              id: data.persons.length > 0 ? data.persons[0].id : null,
              user_id: this.formData?.persons?.length + 1,
              title: this.formData.title,
              position: null,
              phone: null,
              email: null,
              phones: this.formData.phones,
              emails: this.formData.emails
            }
          ];
        }

        await new Api()[this.isEditPage ? 'editUser' : 'addUser'](data).then(() => {
          if (this.isEditPage) {
            window.location.reload();
          } else {
            const r = this.$router.resolve({
              name: 'index',
              query: {
                tab: 'users'
              }
            });
            window.location.assign(r.href);
          }
        });
      }
    },
    resetData() {
      for (const prop in this.formData) {
        this.formData[prop] = Array.isArray(this.formData[prop]) ? [] : null;
      }
      this.contractsList = [];
    },
    isChange(field) {
      if (this.initialData[field] === this.formData[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .r-textarea__label {
  color: rgba(4, 21, 62, 0.32);
}

.grid {
  grid-template-columns: 150px auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}
::v-deep .r-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}
</style>
