import Api from '@/api/Api';
import { generateContractsForTable } from '../../helpers/utils';

const api = new Api();
export default {
  state: () => ({
    contracts: [],
    contractsLoading: true,
    typeOfLaw: [
      {
        id: 'OBJECT_PLACEMENT',
        title: 'Под размещение объектов'
      },
      {
        id: 'RENT',
        title: 'Аренда'
      },
      {
        id: 'TIMELESS',
        title: 'Бессрочное пользование'
      }
    ],
    typeOfLawObj: {
      OBJECT_PLACEMENT: 'Под размещение объектов',
      RENT: 'Аренда',
      TIMELESS: 'Бессрочное пользование'
    },
    headings: [
      {
        title: 'Наименование',
        field: 'title'
      },
      {
        title: 'Ответственный',
        field: 'responsible'
      },
      {
        title: 'Кадастровый номер',
        field: 'cadastralNum'
      },
      {
        title: 'Договор с',
        field: 'start'
      },
      {
        title: 'по',
        field: 'fin'
      },
      {
        title: 'Площадь, м2',
        field: 'square'
      },
      {
        title: 'Вид права',
        field: 'typeOfLaw'
      },
      {
        title: 'Документ на землю',
        field: 'contractNum'
      },
      {
        title: 'Код',
        field: 'code'
      },
      {
        title: 'Вид использования',
        field: 'type'
      }
    ]
  }),
  mutations: {
    setContracts(state, res) {
      state.contracts = res;
    },
    setLoading(state, bool) {
      state.contractsLoading = bool;
    }
  },
  actions: {
    async getContracts(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getContracts();
      ctx.commit('setContracts', res);
      ctx.commit('setLoading', false);
      return res;
    }
  },
  getters: {
    getContractsForTable(state) {
      return generateContractsForTable(state?.contracts, state.typeOfLawObj);
    }
  }
};
