<template>
  <div>
    <div
      class="rir-select__content"
      :class="{
        readonly
      }"
      v-clickOutside="hide"
    >
      <rir-input
        readonly
        :label="label"
        :value="!!value ? value.cadastralNum : null"
        class="rir-select"
        @click.native="showSelectManage"
      >
        <a
          class="rir-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon
            :size="20"
            :class="{
              active: showSelect,
            }"
            icon="arrow-down"
          />
        </a>
      </rir-input>

      <div
        class="rir-select__list"
        v-show="showSelect"
      >
        <div class="rir-select__list_item">
          <rir-input
            label="Поиск"
            v-model="inputMask"
          >
            <r-icon
              :size="16"
              slot="before"
              icon="search"
            />
          </rir-input>
        </div>
        <div
          class="scroll_container"
          v-if="filteredUsers.length > 0"
        >
          <div
            class="rir-select__list_item"
            v-for="obj of filteredUsers"
            :key="obj.id"
            :class="{ active: !!value ? obj.id === value.id : false }"
            @click="selectItem(obj)"
          >
            <p
              class="sulguni opacity-72"
              :inner-html.prop="obj.cadastralNum | highlights(searchText)"
            />
            <p class="briscola opacity-48">
              {{concatAddress(obj) || '' }}
            </p>
          </div>
        </div>
        <p
          class="taleggio mt-3 mb-3"
          style="text-align: center"
          v-else
        >
          Ничего не найдено
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import {getConcatAddress} from "../helpers/utils";

export default {
  name: 'CadastralSelectSearchInput',
  filters: {
    highlights(stringToSearch, searchTerm) {
      if (searchTerm === '') return stringToSearch;
      const iQuery = new RegExp(searchTerm, 'ig');
      return stringToSearch.toString().replace(iQuery, matchedText => `<span style='color: #3D75E4' class='highlight'>${matchedText}</span>`);
    }
  },
  directives: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      default: 'Учётная запись пользователя'
    },
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      searchText: '',
      selected: {},
      showSelect: false
    };
  },
  computed: {
    inputMask: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (val) {
          const mask = val
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1:$2')
            .replace(/(\d{2})(\d)/, '$1:$2');
          this.searchText = mask.length ? mask : null;
        }
      }
    },
    filteredUsers() {
      if (this.searchText && this.searchText.length > 0) {
        return this.items.filter(result => result.cadastralNum.toLowerCase().includes(this.searchText.toLowerCase()));
      }
      return this.items;
    }
  },
  activated() {
    this.selected = this.$props.value;
  },
  methods: {
    concatAddress(el) {
      return getConcatAddress(el);
    },
    showSelectManage() {
      if (this.readonly) return;
      this.showSelect = !this.showSelect;
    },
    selectItem(user) {
      if (this.readonly) return;
      this.$emit('change', user);
      this.selected = user;
      this.showSelect = false;
      this.searchText = '';
    },
    hide() {
      this.showSelect = false;
      this.searchText = '';
    }
  }
};
</script>

<style scoped lang="scss">
.rir-select__content {
  position: relative;

  ::v-deep .rir-input__body {
    pointer-events: none;
  }
}

.rir-select__list {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  border-radius: 8px;
  width: 100%;
  left: 0;
  top: calc(100% + 8px);
  padding: 8px 0;
  z-index: 12;
  max-height: 250px;

  .scroll_container {
    overflow-y: auto;
    max-height: 178px;
    scrollbar-color: rgba(#04153E, 0.08) transparent;

    &::-webkit-scrollbar-track {
      width: 4px;
      background: #FFFFFF;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background: #e4edfb;
      border-radius: 2px;
      opacity: 0.72;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #e4edfb;
      border-radius: 2px;
      opacity: 0.72;
    }
  }

  &_item {
    padding: 8px 20px;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    p {
      transition: 0.2s ease-in-out;
    }

    &:hover,
    &.active {
      background: rgba(#3d75e4, 0.06);

      p.sulguni {
        opacity: 1 !important;
      }

      p.briscola {
        opacity: 0.56 !important;
      }
    }
  }
}
.rir-select__content.readonly {
  user-select: none!important;
  pointer-events: none!important;
  opacity: .72;
  *{
    user-select: none!important;
    pointer-events: none!important;
  }
}
</style>
