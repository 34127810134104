<template>
  <div>
    <div
      class="r-select__content"
      v-clickOutside="hide"
      :class="{
        readonly
      }"
    >
      <r-input
        readonly
        :label="label"
        :value="!!value ? value.title : null"
        class="r-select"
        @click.native="showSelectManage"
      >
        <a
          class="r-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon fill="rocky" size="16"
            :size="20"
            :class="{
              active: showSelect,
            }"
            icon="arrow-down"
          />
        </a>
      </r-input>

      <div
        class="r-select__list"
        v-show="showSelect"
      >
        <div class="r-select__list_item">
          <r-input
            label="Поиск"
            v-model="searchText"
          >
            <r-icon fill="rocky" size="16"
              :size="16"
              slot="before"
              icon="search"
            />
          </r-input>
        </div>
        <div
          class="scroll_container"
          v-if="filteredObjects.length > 0"
        >
          <div
            class="r-select__list_item"
            v-for="obj_item of filteredObjects"
            :key="obj_item.id"
            :class="{ active: !!value ? obj_item.id === value.id : false }"
            @click="selectItem(obj_item)"
          >
            <p
              class="sulguni opacity-72"
              :inner-html.prop="obj_item.title | highlights(searchText)"
            />
            <p class="briscola opacity-48">
              {{ $store.state.users.userTypesObj[obj_item.type] || '' }}
            </p>
          </div>
        </div>
        <p
          class="taleggio mt-3 mb-3"
          style="text-align: center"
          v-else
        >
          Ничего не найдено
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'SelectSearchInput',
  filters: {
    highlights(stringToSearch, searchTerm) {
      if (searchTerm === '') return stringToSearch;
      const iQuery = new RegExp(searchTerm, 'ig');
      return stringToSearch.toString().replace(iQuery, matchedText => `<span style='color: #3D75E4' class='highlight'>${matchedText}</span>`);
    }
  },
  directives: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      default: 'Учётная запись пользователя'
    },
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      searchText: '',
      selected: {},
      showSelect: false
    };
  },
  computed: {
    filteredObjects() {
      if (this.searchText && this.searchText.length > 0) {
        return this.items.filter(result => result.title.toLowerCase().includes(this.searchText.toLowerCase()));
      }
      return this.items;
    }
  },
  activated() {
    this.selected = this.value;
  },
  methods: {
    showSelectManage() {
      if (this.readonly) return;
      this.showSelect = !this.showSelect;
    },
    selectItem(objItem) {
      if (this.readonly) return;
      this.$emit('change', objItem);
      this.selected = objItem;
      this.showSelect = false;
      this.searchText = '';
    },
    hide() {
      this.showSelect = false;
      this.searchText = '';
    }
  }
};
</script>

<style scoped lang="scss">
.r-select__content {
  position: relative;
  ::v-deep .r-input__body {
    pointer-events: none;
  }
}

.r-select__list {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  border-radius: 8px;
  width: 100%;
  left: 0;
  top: calc(100% + 8px);
  padding: 8px 0;
  z-index: 12;
  max-height: 250px;

  .scroll_container {
    overflow-y: auto;
    max-height: 178px;
    scrollbar-color: rgba(#04153E, 0.08) transparent;

    &::-webkit-scrollbar-track {
      width: 4px;
      background: #FFFFFF;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background: #e4edfb;
      border-radius: 2px;
      opacity: 0.72;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #e4edfb;
      border-radius: 2px;
      opacity: 0.72;
    }
  }

  &_item {
    padding: 8px 20px;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    p {
      transition: 0.2s ease-in-out;
    }

    &:hover,
    &.active {
      background: rgba(#3d75e4, 0.06);

      p.sulguni {
        opacity: 1 !important;
      }

      p.briscola {
        opacity: 0.56 !important;
      }
    }
  }
}
.r-select__content.readonly {
  user-select: none!important;
  pointer-events: none!important;
  opacity: .72;
  *{
    user-select: none!important;
    pointer-events: none!important;
  }
}
</style>
