<template>
  <r-popover
    :disabled="disabled"
    :fixed="false"
    :click-close="false"
    :content-width-activator="false"
    :content-class="null"
    :content-coefficient-width="1"
    position="center-bottom"
  >
    <template #activator="{ on }">
      <r-button-action
        :title="buttonTitle"
        :is-arrow="true"
        color="rocky"
        size="sulguni"
        v-on="on"
      />
    </template>
    <template #content>
      <div class="check_list__dropdown">
        <r-checkbox
          v-for="(item, index) of checklist"
          :key="item"
          :title="item"
          v-model="model"
          :val="item"
          @input="$emit('input', model)"
        />
      </div>
    </template>
  </r-popover>
</template>

<script>
export default {
  name: 'DropDownCheckList',
  props: {
    checklist: {
      type: Array,
      default: () => []
    },
    disabled: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      model: ['2022'],
      showDropdown: false
    };
  },
  computed: {
    buttonTitle() {
      let str = '';
      this.model.forEach((item, index) => {
        str += `${index > 0 ? ', ' : ''}${item}`;
      });
      return str;
    }
  },
  created() {
    // this.model.push(new)
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.check_list {
  position: relative;

  * {
    user-select: none;
  }

  &__dropdown {
    padding: 20px;
    display: grid;
    grid-gap: 36px;
  }
}
</style>
