<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <admin-header
      :to="{ name: 'admin', query: { tab: 'regions' } }"
      :title="
        !isEditPage ? 'Новый участок' : 'Участок ' + formData.cadastralIdentifier || 'Данные участка'
      "
    >
      <sup
        class="text-c13 color-fargo"
        v-if="!isChangeComp && isEditPage"
      >Не сохранено</sup>
    </admin-header>
    <loader v-if="formDataLoading" />
    <template v-else>
      <p class="anie mt-1">
        Дата создания записи в Росреестре:
        {{ formData.registrationDate | fullDateTime }}
        {{ !formData.registrationDate ? 'Неизвестно' : '' }}
      </p>
      <r-tabs
        v-if="isEditPage && contractsList.length > 0"
        class="mt-7 mb-6"
        :items="[
          {
            id: 'information',
            title: 'Информация',
          },
          {
            id: 'contracts',
            title: 'Договоры',
          },
        ]"
        v-model="activeTabId"
      />
      <div v-show="activeTabId === 'information'">
        <div class="form mt-8 flex">
          <div class="flex flex-1 flex-direction-column">
            <div
              v-for="field of formTypes[this.formData.schemaTypeCode]"
              :key="field.id"
              class="mb-6"
            >
              <r-input
                v-if="field.type === 'input' && field.required"
                :label="field.label"
                :class="{ field_error: $v.formData[field.id].$error }"
                v-model="formData[field.id]"
                :error="$v.formData[field.id].$error && !$v.formData[field.id].required"
                error-message="Поле обязательно для заполнения"
              />
              <r-input
                v-else-if="field.type === 'input'"
                :label="field.label"
                v-model="formData[field.id]"
              />
              <r-date-picker
                v-else-if="field.type === 'date'"
                :is-clear-model="true"
                click-close
                :label="field.label"
                v-model="formData[field.id]"
              />
            </div>
            <div class="flex mt-10">
              <r-button-action
                v-if="isEditPage && contractsList.length < 1"
                title="Удалить участок"
                color="fargo"
                icon="delete"
                @click="onDelete"
              />
              <r-tooltip
                activator-hover
                color="amelie"
                v-if="isEditPage && contractsList.length > 0"
                title="Участок не может быть удалён, так как прикреплён к договорам"
              >
                <template #activator>
                  <r-button-action
                    class="ekas"
                    title="Удалить участок"
                    color="fargo"
                    icon="delete"
                  />
                </template>
              </r-tooltip>
            </div>
          </div>
          <div class="ml-6 flex flex-direction-column flex-1">
            <div class="field_wrapper">
              <rir-map-leaflet
                class="mb-4"
                style="height: 432px"
                @mapInit="initMap"
                @onMapClick="onMapClick"
                :center="formData.marker.coordinates"
              >
                <!--Драг маркер -->
                <l-marker
                  v-if="!!formData.marker.coordinates"
                  :lat-lng="formData.marker.coordinates"
                  @update:lat-lng="onDragMarker"
                  draggable
                >
                  <l-icon
                    :icon-url="$markerIcon().imageHref"
                    :icon-size="$markerIcon().imageSize"
                  />
                </l-marker>
                <l-geo-json
                  :geojson="formData.wgs"
                  :options="{
                    name: 'geojson',
                  }"
                  v-if="!editPolygon"
                />
                <!-- <editable-polygon
                  v-if="editPolygon"
                  v-for="(polygon, index) of formData.wgs.coordinates"
                  :key="'editable-polygon' + index"
                  draggable
                  :lat-lngs="polygon"
                  :color="'#3d75e4'"
                  :weight="1"
                  :fill-color="'#3d75e4'"
                  :fill-opacity="0.4"
                  :editable="editPolygon"
                  :options="{
                    name: 'editable-polygon',
                  }"
                />-->
              </rir-map-leaflet>
              <!-- <r-checkbox
                title="Title text"
                sub-title="SubTitle text"
                v-model="editPolygon"
                @input="onEditPolygon"
              />-->

              <!--<div
                class="error mozzarella color-fargo mb-2 left"
                v-if="$v.formData.wgs.$error && !$v.formData.wgs.required"
              >
                Пожалуйста, выберите участок на карте
              </div>-->
            </div>
            <r-input
              label="Адрес"
              v-model="formData.readableAddress"
              @blur="onChangeAddress"
              :class="{ field_error: $v.formData.readableAddress.$error }"
              :error="$v.formData.readableAddress.$error && !$v.formData.readableAddress.required"
              error-message="Поле обязательно для заполнения"
            />
          </div>
        </div>
        <div class="button_container">
          <r-button
            @click="submit('update')"
            :disabled="isChangeComp"
            :is-loading="isLoading"
            :title="isEditPage ? 'Сохранить' : 'Добавить'"
            width="wide"
          />
        </div>
      </div>
      <div v-show="activeTabId === 'contracts'">
        <contracts-table :list="contractsList" />
      </div>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { LMarker, LIcon, LPolygon, LGeoJson } from 'vue2-leaflet';
import { EditablePolygon } from 'vue2-leaflet-editable';
import { featureGroup } from 'leaflet';
import _ from 'lodash';
import AdminHeader from '../components/AdminHeader';
import PolygonMap from '../components/PolygonMap';
import DeleteModal from '../components/DeleteModal';
import ContractsTable from '../components/ContractsTable';
import Api from '../api/Api';
import { generateContractsForTable, getConcatAddress } from '../helpers/utils';
import rirMapLeaflet from '../components/RirMapLeaflet';
import Loader from '../components/Loader';

export default {
  name: 'Region',
  components: {
    AdminHeader,
    PolygonMap,
    ContractsTable,
    rirMapLeaflet,
    LMarker,
    LIcon,
    LPolygon,
    LGeoJson,
    EditablePolygon,
    Loader

  },
  data() {
    return {
      activeTabId: 'information',
      isSave: true,
      flyButton: false,
      initialData: {},
      region: {},
      contractsList: [],
      map: null,
      formTypes: {
        extract_cadastral_plan_territory: [
          {
            id: 'cadastralIdentifier',
            type: 'input',
            label: 'Кадастровый номер',
            required: true
          },
          {
            id: 'encumbranceTypeValue',
            type: 'input',
            label: 'Основание',
            required: false
          },
          {
            id: 'cadastralCost',
            type: 'input',
            label: 'Кадастровая стоимость',
            required: true
          },
          {
            id: 'square',
            type: 'input',
            label: 'Площадь',
            required: false
          },
          {
            id: 'okato',
            type: 'input',
            label: 'ОКАТО',
            required: false
          },
          {
            id: 'kladr',
            type: 'input',
            label: 'КЛАДР',
            required: false
          },
          {
            id: 'utilisationDoc',
            type: 'input',
            label: 'Вид использования',
            required: false
          },
          {
            id: 'dateReceivedRequest',
            type: 'date',
            label: 'Дата обновления запроса',
            required: true
          }

        ],
        extract_about_property_land: [
          {
            id: 'cadastralIdentifier',
            type: 'input',
            label: 'Кадастровый номер',
            required: true
          },
          {
            id: 'square',
            type: 'input',
            label: 'Площадь',
            required: false
          },
          {
            id: 'cadastralCost',
            type: 'input',
            label: 'Кадастровая стоимость',
            required: true
          },
          {
            id: 'utilisationDoc',
            type: 'input',
            label: 'Вид использования',
            required: false
          },
          {
            id: 'dateReceivedRequest',
            type: 'date',
            label: 'Дата обновления запроса',
            required: true
          }
        ],
        extract_base_params_build: [
          {
            id: 'cadastralIdentifier',
            type: 'input',
            label: 'Кадастровый номер',
            required: true
          },
          {
            id: 'rightHolderResidentName',
            type: 'input',
            label: 'Пользователь',
            required: false
          },
          {
            id: 'yearBuilt',
            type: 'input',
            label: 'Год постройки',
            required: false
          },
          {
            id: 'rightTypeValue',
            type: 'input',
            label: 'Тип пользования',
            required: false
          },
          {
            id: 'square',
            type: 'input',
            label: 'Площадь',
            required: false
          },
          {
            id: 'cadastralCost',
            type: 'input',
            label: 'Кадастровая стоимость',
            required: true
          },
          {
            id: 'dateReceivedRequest',
            type: 'date',
            label: 'Дата обновления запроса',
            required: true
          }
        ],
        extract_base_params_land: [
          {
            id: 'cadastralIdentifier',
            type: 'input',
            label: 'Кадастровый номер',
            required: true
          },
          {
            id: 'rightHolderIndividualName',
            type: 'input',
            label: 'Арендатор',
            required: false
          },
          {
            id: 'encumbranceTypeValue',
            type: 'input',
            label: 'Основание',
            required: false
          },
          {
            id: 'encumbrancePeriodStartDate',
            type: 'date',
            label: 'Сроки с...',
            required: false
          },
          {
            id: 'encumbrancePeriodEndDate',
            type: 'date',
            label: 'Сроки по',
            required: false
          },
          {
            id: 'square',
            type: 'input',
            label: 'Площадь',
            required: false
          },
          {
            id: 'cadastralCost',
            type: 'input',
            label: 'Кадастровая стоимость',
            required: true
          },
          {
            id: 'category',
            type: 'input',
            label: 'Категория',
            required: false
          },
          {
            id: 'utilisationDoc',
            type: 'input',
            label: 'Вид использования',
            required: false
          },
          {
            id: 'dateReceivedRequest',
            type: 'date',
            label: 'Дата обновления запроса',
            required: true
          }
        ]
      },
      formDataLoading: false,
      formData: {
        readableAddress: null,
        cadastralIdentifier: null,
        rightHolderResidentName: null,
        yearBuilt: null,
        rightHolderIndividualName: null,
        encumbranceTypeValue: null,
        rightTypeValue: null,
        encumbrancePeriodStartDate: null,
        encumbrancePeriodEndDate: null,
        okato: null,
        kladr: null,
        square: null,
        cadastralCost: null,
        category: null,
        utilisationDoc: null,
        buildName: null,
        registrationDate: null,
        dateReceivedRequest: null,
        marker: {
          coordinates: this.$cityCenter,
          type: 'Point'
        },
        wgs: []
      },
      mapInstance: null,
      editPolygon: false
    };
  },
  validations: {
    formData: {
      /* wgs: {
        required
      }, */
      cadastralIdentifier: {
        required
      },
      dateReceivedRequest: {
        required
      },
      cadastralCost: {
        required
      },
      readableAddress: {
        required
      }
    }
  },
  computed: {
    isChangeComp() {
      return _.isEqual(this.formData, this.initialData);
    },
    inputMask: {
      get() {
        return this.formData.cadastralIdentifier;
      },
      set(val) {
        if (val) {
          const mask = val
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1:$2')
            .replace(/(\d{2})(\d)/, '$1:$2');
          this.formData.cadastralIdentifier = mask.length ? mask : null;
        }
      }
    },
    orgList() {
      return [];
    },
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    },
    isLoading() {
      return this.$store.state.regionsLoading;
    }
  },
  async activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData(this.$route.params.id);
    this.isShowMap = true;
    this.$v.$reset();
  },

  deactivated() {
    this.isShowMap = false;
    this.activeTabId = 'information';
  },
  mounted() {
    if (!this.isEditPage) {
      this.isSave = false;
    }
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
  },
  methods: {
    ChangeformData(e) {
      this.formData = JSON.parse(e.target.value);
    },
    onEditPolygon(e) {
      /* if (e) {
        this.map.mapObject.eachLayer(layer => {
          if (layer.options.name === 'geojson' && layer?._latlngs) {
            this.formData.wgs.coordinates = layer._latlngs;
            this.editPolygon = true;
          }
        });
      } else {
        const geometry = [];
        this.map.mapObject.eachLayer(layer => {
          if (layer.options.name === 'editable-polygon' && layer?._latlngs) {
            geometry.push(layer._latlngs.map(el => {
              return el.map(poly => [poly.lng, poly.lat])
            })[0]);
            this.editPolygon = true;
          }
        });
        this.formData.wgs.coordinates = geometry;
        this.editPolygon = false;
      } */
    },
    onChangeAddress() {
      this.searchAddress(this.formData.readableAddress);
    },
    initMap(e) {
      this.map = e;
      this.$nextTick().then(() => {
        const group = new featureGroup();

        this.map.mapObject.eachLayer(layer => {
          if (layer.feature !== undefined) group.addLayer(layer);
        });

        this.map.mapObject.fitBounds(group.getBounds(), { padding: [20, 20] });
      });
    },
    // async onArchive() {},
    async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить участок?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    },
    async delete() {
      await new Api().deleteRegion(this.$route.params.id).then(() => {
        const r = this.$router.resolve({
          name: 'index',
          query: {
            tab: 'regions'
          }
        });
        window.location.assign(r.href);
      });
    },
    changeLandPlot(item) {
      this.formData.landPlot = item;
    },
    async setData(id) {
      if (id) {
        this.formDataLoading = true;
        await new Api().getRegionById(id).then(res => {
          this.region = res;
          this.formData = {
            ...this.formData,
            ...res
          };
          if (this.isEditPage && !this.formData.marker?.coordinates.length) {
            this.formData.marker = {
              coordinates: this.$cityCenter,
              type: 'Point'
            };
          }
          this.initialData = {
            ...this.formData,
            ...res
          };
        }).finally(() => {
          this.formDataLoading = false;
        });
        await new Api().getRegionContracts(id).then(res => {
          this.contractsList = generateContractsForTable(res, this.$store.state.contracts.typeOfLawObj);
        });
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        // const formFields = Object.assign({}, ...this.formTypes[this.formData.schemaTypeCode].map(el => ({ [el.id]: this.formData[el.id] })));
        const data = {
          id: this.$route.params.id ? this.$route.params.id : null,
          ...this.formData
        };
        const action = this.$route.params.id ? 'editRegion' : 'createRegion';

        await this.$store.dispatch(action, data).then(() => {
           const r = this.$router.resolve({
            name: 'index',
            query: {
              tab: 'regions'
            }
          });
          window.location.assign(r.href);
        }).finally(() => {
        });
      }
    },
    resetData() {
      for (const prop in this.formData) {
        this.formData[prop] = this.initialData[prop];
      }

      if (!this.isEditPage) {
        this.formData.marker = {
          coordinates: this.$cityCenter,
          type: 'Point'
        };
      }
    },

    async onMapClick(coords, addressObj) {
      /*
      if (coords?.length === 2) {
        this.formData.marker.coordinates = coords;
        // this.searchAddress(coords);
        if (addressObj) {
          this.formData.postalCode = addressObj?.postal_code || null;
          addressObj.Components.forEach((item) => {
            this.formData[item.kind] = item.name;
          });
        }

      } */
    },
    async onDragMarker(e) {
      this.formData.marker.coordinates = [e.lat, e.lng];
      this.searchAddress(this.formData.marker.coordinates);

      /*

        const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
          this.formData.marker.coordinates = coords;
          await window.ymaps
            .geocode(coords, {
              results: 1
            })
            .then(res => {
              const firstGeoObject = res.geoObjects.get(0);
              const YmapsAddress = firstGeoObject.properties.get('metaDataProperty').GeocoderMetaData
                .Address;
              this.formData.postalCode = YmapsAddress?.postal_code || null;
              YmapsAddress.Components.forEach(item => {
                if (item.kind === 'province') item.kind = 'region';
                if (item.kind === 'locality') item.kind = 'city';
                if (item.kind === 'house') item.kind = 'lvl1';
                if (item.kind === 'corpus') item.kind = 'lvl2';
                if (item.kind === 'building') item.kind = 'lvl3';
                if (this.formData[item.kind] !== undefined) {
                  this.formData[item.kind] = item.name;
                }
              });
              // this.isChange('address');
            });
        } */
    },
    async searchAddress(address) {
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.formData.readableAddress;
      }

      this.addressLoading = true;
      if (data) {
        const res = await api.getAddress(JSON.stringify(data));

        if (isFindAddress && res?.address) {
          if (res?.addressFull?.street && res?.addressFull?.house) {
            this.formData.readableAddress = `${res.addressFull.street} ${res.addressFull.house}`;
          } else {
            this.formData.readableAddress = res.address;
          }
        } else if (!isFindAddress && res?.lat && res?.lng) {
          this.formData.marker.coordinates = [res.lat, res.lng];
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99999;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.left {
      right: auto;
      left: 16px;
    }

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .r-textarea__label {
  color: rgba(4, 21, 62, 0.32);
}

.grid {
  grid-template-columns: 150px auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}

::v-deep .r-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}
</style>
