var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex mb-6"},[(this.$store.getters.userIsRoot)?_c('router-link',{staticClass:"mr-6",attrs:{"to":"user"}},[_c('r-button-action',{attrs:{"icon":"add","title":"Добавить пользователя"}})],1):_vm._e()],1),_c('div',{staticClass:"flex mb-6"},[_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":[
        {
          id: 'ALL',
          title: 'Все',
        },
        ..._vm.$store.state.users.userTypes,
      ],"label":"Вид деятельности"},on:{"input":function($event){return _vm.changeFilter($event, 'selectedUserType')}},model:{value:(_vm.selectedUserType),callback:function ($$v) {_vm.selectedUserType=$$v},expression:"selectedUserType"}}),_c('r-input',{staticClass:"flex-1",attrs:{"label":"Поиск","before-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),(_vm.isLoading)?_c('loader'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"flex mb-6 align-items-center"},[_c('div',{staticClass:"ml-auto parmigiano color-titanic opacity-48"},[_vm._v(" "+_vm._s(!!_vm.searchText ? 'Найдено' : 'Всего')+" "+_vm._s(_vm.countObjects)+" ")])]),_c('scroll-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"rows_space-between",attrs:{"list":_vm.filteredObjects,"headings":_vm.headings,"columns-grid":_vm.tableGridColumns,"row-link":"/user/","start-sorted-column":"name","delete-function":_vm.openDeleteModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }