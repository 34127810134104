export const wordMatch = (left, right) => {
  // 0 - полное совпадение
  // 1 - нет совпадений
  left = left?.toLowerCase();
  right = right?.toLowerCase();

  if (left.includes(right)) {
    return 0;
  }

  left = `\b\b${left.toLowerCase()}\f\f`;
  right = `\b\b${right.toLowerCase()}\f\f`;

  let dist = -4;

  for (let i = 0; i < left.length - 2; ++i) {
    if (!right.includes(left.slice(i, i + 3))) ++dist;
  }

  for (let i = 0; i < right.length - 2; ++i) {
    if (!left.includes(right.slice(i, i + 3))) ++dist;
  }

  return Math.max(0, dist) / (left.length + right.length - 8);
};
export const getNumberOfDays = endDate => {
  const date1 = new Date();
  const date2 = new Date(endDate);

  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};
export const getEmptyField = (details, arr = []) => {
  for (const elem in details) {
    if (!details[elem]) arr.push(elem);
  }
  return arr;
};

export const getDayAddition = num => {
  const preLastDigit = num % 100 / 10;
  if (preLastDigit == 1) {
    return 'дней';
  }
  switch (num % 10) {
    case 1:
      return 'день';
    case 2:
    case 3:
    case 4:
      return 'дня';
    default:
      return 'дней';
  }
};
const getFileSize = file => {
  let fileSize = '0 КБайт';
  console.log(file);
  if (file?.size > 1024 * 1024) {
    const size = file?.size / 1024 / 1024;
    fileSize = `${size.toFixed(1)} МБайт`;
  } else {
    const size = file?.size / 1024;
    fileSize = `${size.toFixed(0)} КБайт`;
  }
  return fileSize;
};
export const formattedFiles = files => {
  const formattedFiles = [];
  files.forEach(el => {
    if (el?.files?.length) {
      const fileList = el.files.map(el => ({
        contentType: el.type,
        filename: el.name, // .split('.')[0]
        icon: false,
        url: el.url,
        sizeFile: el.size
      }));
      formattedFiles.push(...fileList);
    } else {
      formattedFiles.push(el);
    }
  });
  return formattedFiles;
};
export const getConcatAddress = item =>
// postCode - почтовый код
// province - регион
// city - город
// district - район
// street
// house
// corpus
// building

  ''.concat(
    item?.postCode ? `${item.postCode}, ` : '',
    item?.province ? `${item.province}, ` : '',
    item?.city ? `${item.city}, ` : '',
    item?.district ? `${item.district}, ` : '',
    item?.street ? `${item.street}, ` : '',
    item?.lvl1 ? `${item.lvl1}, ` : '',
    item?.lvl2 ? `${item.lvl2}, ` : '',
    item?.lvl3 ? `${item.lvl3}, ` : ''
  ).slice(0, -2);
export const formattedDate = (date, format = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}) => {
  if (!date) return;
  return new Date(date).toLocaleString('Ru-ru', format);
};
const dateNow = new Date();
export const generateContractsForTable = (contracts, typeOfLawObj) => contracts.map(el => ({
  id: el.id,
  active: el?.active,
  typeOfLawKey: el?.typeOfLaw,
  mapObject: el?.mapObject,
  endThisMonth: (new Date(el?.fin).getFullYear() === dateNow.getFullYear())
    && (new Date(el?.fin).getMonth() === dateNow.getMonth()),
  finalDate: el?.fin,
  columns: {
    title: el?.lessee?.title,
    person: el?.lessee?.persons[0]?.title,
    cadastralNum: {
      title: el?.mapObject?.cadastralIdentifier,
      subtitle: el?.mapObject?.address
    },
    start: formattedDate(el?.start),
    fin: formattedDate(el?.fin),
    square: el?.square,
    typeOfLaw: typeOfLawObj[el?.typeOfLaw],
    contractNum: el?.contractNum,
    code: el?.code,
    type: el?.type
  }
}));
