var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_history"},[_c('div',{staticClass:"flex mb-6"},[_c('r-date-picker',{staticClass:"flex-1 mr-6",attrs:{"is-clear-model":true,"click-close":true,"period":true,"label":"Дата загрузки"},model:{value:(_vm.sortDate),callback:function ($$v) {_vm.sortDate=$$v},expression:"sortDate"}}),_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":[
        {
          code: 'ALL',
          value: 'Все',
        },
        ...this.$store.state.regions.cadastralXMLTypes,
      ],"title-value":"value","id-value":"code","label":"Название файла"},model:{value:(_vm.selectedXMLTypes),callback:function ($$v) {_vm.selectedXMLTypes=$$v},expression:"selectedXMLTypes"}}),_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":[
        {
          code: 'ALL',
          value: 'Все',
        },
        ..._vm.cadastralFileStatuses,
      ],"title-value":"value","id-value":"code","label":"Статус"},model:{value:(_vm.selectedCadastralFileStatuses),callback:function ($$v) {_vm.selectedCadastralFileStatuses=$$v},expression:"selectedCadastralFileStatuses"}})],1),_c('r-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredObjects,"is-fixed-header":"","color-header":"amelie","is-loading":_vm.loading},scopedSlots:_vm._u([{key:"createdAt",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("fullDateTime")(item['createdAt']))+" ")]}},{key:"fileName",fn:function({ item }){return [_c('r-shorter',{attrs:{"text":item['fileName'] || '—',"count-row":1,"tooltip-options":{
          position: 'center-right',
          alignTitle: 'start',
          maxWidth: '300px',
        }}})]}},{key:"numberOfAlreadyExistingMapObjects",fn:function({ item }){return [_vm._v(" "+_vm._s(item['numberOfAlreadyExistingMapObjects'])+" ")]}},{key:"numberOfCreatedNewMapObjects",fn:function({ item }){return [_vm._v(" "+_vm._s(item['numberOfCreatedNewMapObjects'])+" ")]}},{key:"numberOfUpdatedMapObjects",fn:function({ item }){return [_vm._v(" "+_vm._s(item['numberOfUpdatedMapObjects'])+" ")]}},{key:"numberOfCreatedDuplicatesOfMapObjects",fn:function({ item }){return [_c('div',{staticClass:"pointer sulguni",on:{"click":function($event){return _vm.openModalHistoryById(item.id, 'cadastralIdentifiersOfCreatedDuplicatesOfMapObjects')}}},[_vm._v(" "+_vm._s(item['numberOfCreatedDuplicatesOfMapObjects'])+" ")])]}},{key:"numberOfFailedMapObjects",fn:function({ item }){return [_c('div',{staticClass:"pointer sulguni",on:{"click":function($event){return _vm.openModalHistoryById(item.id, 'cadastralIdentifiersOfFailedMapObjects')}}},[_vm._v(" "+_vm._s(item['numberOfFailedMapObjects'])+" ")])]}},{key:"numberOfMapObjectsWithoutCoords",fn:function({ item }){return [_c('div',{staticClass:"pointer sulguni",on:{"click":function($event){return _vm.openModalHistoryById(item.id, 'cadastralIdentifiersOfMapObjectsWithoutCoords')}}},[_vm._v(" "+_vm._s(item['numberOfMapObjectsWithoutCoords'])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }