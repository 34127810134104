import Api from '@/api/Api';
import { getConcatAddress } from '../../helpers/utils';

const api = new Api();
export default {
  state: () => ({
    regions: [],
    regionsFiles: [],
    regionsLoading: true,
    regionCategory: [
      {
        id: 'Земли населенных пунктов',
        title: 'Земли населенных пунктов'
      },
      {
        id: 'Черта города',
        title: 'Черта города'
      },
      {
        id: 'Историческая зона',
        title: 'Историческая зона'
      },
      {
        id: 'Нежилое',
        title: 'Нежилое'
      }
    ],
    cadastralXMLTypes: [],
    selectedXMLTypes: 'ALL'
  }),
  mutations: {
    setRegions(state, res) {
      state.regions = res;
    },
    setRegionsFiles(state, res) {
      state.regionsFiles = res;
    },
    setLoading(state, bool) {
      state.regionsLoading = bool;
    },
    setCadastralXMLTypes(state, res) {
      state.cadastralXMLTypes = res;
    },
    setSelectedXMLTypes(state, type){
      state.selectedXMLTypes = type
    }
  },
  actions: {
    async getRegions(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getRegions();
      ctx.commit('setRegions', res);
      ctx.commit('setLoading', false);
      return res;
    },
    async createRegion(cxt, payload) {
      return await api.createRegion(payload);
    },
    async editRegion(cxt, payload) {
      return await api.editRegion(payload);
    },
    async getCadastralXMLTypes(ctx) {
      const res = await api.getCadastralXMLTypes();
      ctx.commit('setCadastralXMLTypes', res);
    }
  },
  getters: {
    getRegionsForTable(state) {
      return state.regions.map(el => ({
        id: el.id,
        type: 'Feature',
        geometry: el.wgs,
        properties: el,
        active: el?.active || true,
        columns: {
          cadastralNum: {
            title: el?.cadastralIdentifier,
            subtitle: el?.type || ''
          },
          address: el.readableAddress,
          square: el?.square,
          cadastralCost: el?.cadastralCost,
          category: el?.category,
          dateCreated: el?.dateCreated
        }
      }));
    }
  }
};
