<template>
  <div class="balloon_wrapper">
    <div
      class="rir-map-balloon pa-4 "
    >
      <div
        v-for="(slide, index) of item.slides"
        class="slide"
        :key="slide.id"
        v-show="(index + 1) === active"
      >
        <router-link
          :to="'contract/'+ slide.id"
          class="сaprino opacity-48 mb-3 flex pointer"
        >
          {{ slide.columns.cadastralNum.title }}
        </router-link>
        <router-link
          class="roquefort mb-1 pointer"
          :to="'contract/'+ slide.id"
        >
          {{ slide.columns.title }}
        </router-link>
        <p class="сaprino opacity-72 mb-3">
          {{ slide.columns.cadastralNum.subtitle }}
        </p>
        <div class="flex align-center mb-4">
          <r-icon
            icon="order"
            fill="rocky"
            class="mr-2"
          />
          {{ slide.columns.typeOfLaw }}
        </div>
        <div class="flex align-center mb-4">
          <r-icon
            icon="calendar"
            fill="rocky"
            class="mr-2"
          />
          <div v-if="slide.columns.fin">
            {{ slide.columns.start }} – <span
            :class="{'text-fargo': slide.endThisMonth}">{{ slide.columns.fin }}</span>
          </div>
          <span v-else>с {{ slide.columns.start }}</span>
        </div>
        <div class="flex align-center justify-space-between">
          <rir-button
            class="text-rocky sulguni"
            color="transparent"
            content-width
            @click="copyItem(slide.columns.id)"
          >
            <r-icon
              class="mr-2"
              icon="copy"
              fill="rocky"
            />
            Дублировать
          </rir-button>
          <div>
            <rir-button
              v-if="$store.getters.userIsRoot"
              class="text-fargo sulguni"
              color="transparent"
              content-width
              @click="onDelete"
            >
              <r-icon
                icon="archive"
                fill="rocky"
              />
            </rir-button>
          </div>
        </div>
      </div>
      <div
        class="pagination flex align-center mt-4"
        v-if="slidesLength > 1"
      >
        <div
          class="dot"
          v-for="i of slidesLength"
          :key="i"
          :class="{ active: i === active }"
          @click="jump(i)"
        />
      </div>
    </div>
    <div
      class="prev slider_button"
      @click="move(-1)"
      v-if="slidesLength > 1"
    >
      <r-icon
        icon="arrow-left"
        fill="rocky"
      />
    </div>
    <div
      @click="move(1)"
      class="next slider_button"
      v-if="slidesLength > 1"
    >
      <r-icon
        icon="arrow-right"
        fill="rocky"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContractBalloon',
  props: {
    openDeleteModal: {
      type: Function,
      default: () => {
      }
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      active: 1
    };
  },
  computed: {
    slidesLength() {
      return this.item.slides.length;
    }
  },
  methods: {
    copyItem(id) {
      this.$router.push({path: `contract/${id}`, query: {copy: true}});
    },
    move(amount) {
      let newActive;
      const newIndex = this.active + amount;
      if (newIndex > this.slidesLength) newActive = 1;
      if (newIndex === 0) newActive = this.slidesLength;
      this.active = newActive || newIndex;
    },
    jump(index) {
      this.active = index;
    },
    onDelete() {
      this.$props.openDeleteModal(this.$props.item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.balloon_wrapper {
  width: 276px;
  padding: 0px 16px;

}

.rir-map-balloon {
  position: relative;
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  background: #FFFFFF;

  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}

.slider_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
  opacity: 0.72;
  top: 50%;
  margin-top: -16px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.pagination {
  .dot {
    transition: .2s;
    background: #C0D6F6;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &.active{
      background: #3D75E4;
    }
    &:not(:last-child){
      margin-right: 8px;
    }
  }
}

</style>
