<template>
  <div id="app">
    <router-view />
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
    <r-notification/>
  </div>
</template>

<script>
export default {
  name: 'KumiAdminApp',
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  async created() {
  }
};
</script>
