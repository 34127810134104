import ApiService from './ApiService';

let proxy = '';
if (process.env.NODE_ENV === 'development') {
  proxy = '/proxy';
}

export default class Api extends ApiService {
  /* async getUsers() {
    const {data} = await this._axios.get('ajax.php?action=getMasters');
    //const {data} = await this._axios.get(`/auth/rest/user/list`);
    return data;
  } */
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post(`${proxy}/mob_ajax.php?action=geosearch`, payload);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/KUMI');
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  // regions
  async getСoordinatesSystem() {
    const { data } = await this._axios.get(`${proxy}/fromHttp.php?url=functions/proj4php5.2/src/proj4php/defs/geobridge_keys.json`); /// proxy/
    return data;
  }

  async parseFromXMLfile(payload) {
    const { data } = await this._axios.post('ajax.php?action=parseFromXMLfile', {
      ...payload
    });
    return data;
  }

  async getRegions() {
    // получить список участков
    const { data } = await this._axios.get('/cityProperty/rest/kumi/plot');
    return data;
  }

  async getRegionById(id) {
    // получить список участков
    const { data } = await this._axios.get(`/cityProperty/rest/kumi/plot/id/${id}`);
    return data;
  }

  async getRegionContracts(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/kumi/contract/map_object/${id}`);
    return data;
  }

  async createRegion(payload) {
    const { data } = await this._axios.post('/cityProperty/rest/kumi/plot', {
      ...payload
    });
    return data;
  }

  async editRegion(payload) {
    const { data } = await this._axios.put(
      '/cityProperty/rest/kumi/plot',
      {
        ...payload
      }
    );
    return data;
  }

  async deleteRegion(id) {
    const { data } = await this._axios.delete(`/cityProperty/rest/kumi/plot/id/${id}`);
    return data;
  }

  async uploadRegions({ file, projStr }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projStr', projStr);
    const { data } = await this._axios.post('/cityProperty/rest/kumi/plot/upload_xml', formData);
    return data;
  }

  async getUploadHistory() {
    const { data } = await this._axios.get('/cityProperty/rest/kumi/plot/cadastral-files');
    return data;
  }

  async getUploadHistoryById(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/kumi/plot/cadastral-files/${id}`);
    return data;
  }

  async getCadastralFileStatuses() {
    const { data } = await this._axios.get('/cityProperty/rest/dictionary/cadastral-file-statuses');
    return data;
  }

  async getCadastralXMLTypes() {
    const { data } = await this._axios.get('/cityProperty/rest/dictionary/cadastral-xml-schema-types');
    return data;
  }
  // users

  async getUsers(type) {
    const { data } = await this._axios.get('/cityProperty/rest/kumi/lessee', {
      params: {
        type
      }
    });

    return data;
  }

  async getUserById(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/kumi/lessee/${id}`);
    return data;
  }

  async getUserContracts(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/kumi/contract/user/${id}`);
    return data;
  }

  async addUser(payload) {
    const { data } = await this._axios.post('/cityProperty/rest/kumi/lessee', payload);
    return data;
  }

  async editUser(payload) {
    const { data } = await this._axios.put('/cityProperty/rest/kumi/lessee', payload);
    return data;
  }

  async deleteUser(id) {
    const { data } = await this._axios.delete(`/cityProperty/rest/kumi/lessee/${id}`);
    return data;
  }

  async deletePersonUser(id) {
    const { data } = await this._axios.delete(`/cityProperty/rest/kumi/lessee/person/${id}`);
    return data;
  }

  // contracts

  async getContracts(type) {
    const { data } = await this._axios.get('/cityProperty/rest/kumi/contract', {
      params: {
        type
      }
    });
    return data;
  }

  async getContractById(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/kumi/contract/${id}`);
    return data;
  }

  async addContract(payload) {
    const { data } = await this._axios.post('/cityProperty/rest/kumi/contract', payload);
    return data;
  }

  async editContract(payload) {
    const { data } = await this._axios.put('/proxy/cityProperty/rest/kumi/contract', payload);
    return data;
  }

  async deleteContract(id) {
    const { data } = await this._axios.delete(`/cityProperty/rest/kumi/contract/${id}`);
    return data;
  }
}
