<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <admin-header :title="!isEditPage ? 'Новый договор' : 'Данные договора'">
      <sup
        class="text-c13 color-fargo"
        v-if="!isSave && isEditPage"
      >Не сохранено</sup>
    </admin-header>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="form mt-8 flex">
        <div class="flex flex-1 flex-direction-column">
          <r-select
            class="flex-1"
            :class="{ field_error: $v.formData.lessee.$error }"
            is-search
            :icon="null"
            label="Пользователь"
            type="default"
            :error="$v.formData.lessee.$error && !$v.formData.lessee.required"
            error-message="Поле обязательно для заполнения"
            @input="changeUser"
            :disabled="!formData.active"
            v-model="formData.lessee"
            :items="users"
            return-object
          />
          <p class="mozzarella opacity-48 ml-4">
            {{
              !!formData.lessee ? $store.state.users.userTypesObj[formData.lessee.type] : ''
            }}
          </p>

          <div class="flex mt-6">
            <r-select
              class="flex-1"
              :class="{ field_error: $v.formData.persons.$error }"
              is-search
              label="Сотрудник"
              :error="$v.formData.persons.$error && !$v.formData.persons.required"
              error-message="Поле обязательно для заполнения"
              @input="changeWorker"
              :disabled="!formData.active || !canPersonsDelete"
              v-model="selectedWorker"
              :items="workerItems"
              return-object
            />
            <r-button-simple
              size="larishae"
              class="ml-2"
              @click="addWorker"
              icon="add"
              :disabled="!!!selectedWorker && !formData.active"
            />
          </div>
          <selected-list
            v-show="formData.persons.length"
            class="mt-4"
            icon="avatar"
            v-model="formData.persons"
            sub-value="type"
            text-value="title"
            @input="workersChange"
            :can-delete="canPersonsDelete"
          />
          <r-select
            class="mt-10"
            :items="$store.state.contracts.typeOfLaw"
            label="Вид права"
            :class="{ field_error: $v.formData.typeOfLaw.$error }"
            v-model="formData.typeOfLaw"
            :readonly="!formData.active"
            :error="$v.formData.typeOfLaw.$error && !$v.formData.typeOfLaw.required"
            error-message="Поле обязательно для заполнения"
          />

          <r-input
            class="mt-10"
            label="Номер документа на землю"
            :class="{ field_error: $v.formData.contractNum.$error }"
            :error="$v.formData.contractNum.$error && !$v.formData.contractNum.required"
            error-message="Поле обязательно для заполнения"
            v-model="formData.contractNum"
            @blur="isChange('contractNum')"
            :readonly="!formData.active"
          />
          <div class="flex mt-6">
            <r-date-picker
              :is-clear-model="true"
              class="mr-4 flex-1"
              :disabled="!formData.active"
              click-close
              label="C"
              v-model="formData.start"
              :class="{ field_error: $v.formData.start.$error }"
              :error="$v.formData.start.$error && !$v.formData.start.required"
              @input="isChange('start')"
              error-message="Поле обязательно для заполнения"
            />
            <!--:readonly="!formData.start || formData.typeOfLaw === 'TIMELESS' || !formData.active"-->
            <r-date-picker
              :is-clear-model="true"
              class="flex-1"
              click-close
              label="По"
              v-model="formData.fin"
              @input="isChange('fin')"
              :start-date="formData.start"
              :class="{ field_error: $v.formData.fin.$error }"
              :error="$v.formData.fin.$error && !$v.formData.fin.required"
              error-message="Поле обязательно для заполнения"
            />
          </div>
          <div class="flex mt-10">
            <r-input
              class="flex-1 mr-4"
              label="Площадь, м2"
              v-model="formData.square"
              @blur="isChange('square')"
              :readonly="!formData.active"
            />
            <r-input
              class="flex-1"
              label="Код использования"
              v-model="formData.code"
              @blur="isChange('code')"
              :readonly="!formData.active"
            />
          </div>
          <r-input
            class="flex-1 mt-6"
            label="Вид использования"
            v-model="formData.type"
            @blur="isChange('type')"
            :readonly="!formData.active"
          />
          <r-textarea
            @input="isChange('description')"
            :rows="6"
            class="mt-10"
            label="Описание"
            v-model="formData.description"
            :readonly="!formData.active"
          />
          <div class="flex mt-10">
            <r-button-action
              v-if="isEditPage && !formData.active && !isCopy"
              @click="copyItem(formData.id)"
              icon="copy"
              title="Дублировать"
            />
            <r-button-action
              v-if="isEditPage && formData.active"
              icon="delete"
              title="Архивировать договор"
              @click="onArchive"
            />
            <!--<r-button
              v-if="isEditPage"
              class="ml-6 color-fargo sulguni"
              color="transparent"
              content-width
              @click="onDelete"
            >
              <r-icon size="16"
                class="mr-2"
                icon="delete"
                fill="fargo"
              />
              Удалить договор
            </r-button>-->
          </div>
        </div>
        <div class="ml-6 flex flex-direction-column flex-1">
          <rir-map-leaflet
            class="mb-4"
            style="height: 432px"
            @mapInit="initMap"
            @onMapClick="onMapClick"
          >
            <l-layer-group v-if="!!formData.mapObject">
              <l-marker
                v-if="mapHasMarker"
                :lat-lng="formData.marker.coordinates"
                @update:lat-lng="onDragMarker"
                draggable
              >
                <l-icon
                  :icon-url="$markerIcon().imageHref"
                  :icon-size="$markerIcon().imageSize"
                />
              </l-marker>
              <l-geo-json
                :geojson="formData.mapObject.wgs"
              />
            </l-layer-group>
          </rir-map-leaflet>
          <!--<rir-map
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-4"
            @onInit="initMap"
          >
            <div v-if="!!formData.mapObject">
              <div v-if="formData.mapObject.marker.coordinates.length">
                <ymap-marker
                  :coords="formData.mapObject.marker.coordinates"
                  marker-id="marker"
                  :options="{
                    draggable: $store.getters.userIsRoot,
                  }"
                  :icon="$markerIcon('mapPin')"
                  @dragend="onDragMarker"
                />
              </div>
              <ymap-marker
                marker-id="polygon"
                marker-type="polygon"
                :coords="formData.mapObject.coordinates || formData.mapObject.wgs.coordinates || []"
                :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                :marker-stroke="{ color: '#3d75e4', width: 2 }"
                fill-rule="nonZero"
              />
            </div>
          </rir-map>-->
          <r-select
            :is-search="true"
            :class="{ field_error: $v.formData.mapObject.$error }"
            :error="$v.formData.mapObject.$error && !$v.formData.mapObject.required"
            label="Земельный участок"
            error-message="Поле обязательно для заполнения"
            :return-object="true"
            @input="changeLandPlot"
            :items="landPlotItems"
            :disabled="!formData.active"
            v-model="formData.mapObject"
            title-value="cadastralIdentifier"
            sub-title-value="readableAddress"
          />
          <template v-if="formData.mapObject">
            <div class="grid mt-4">
              <p class="mozzarella opacity-48 flex-1">
                Адрес участка
              </p>
              <p class="feta">
                {{ formData.mapObject ? formData.mapObject.readableAddress : '—' }}
              </p>
            </div>
            <div class="grid mt-4">
              <p class="mozzarella opacity-48 flex-1">
                Общая площадь, м2
              </p>
              <p class="feta">
                {{ formData.mapObject ? formData.mapObject.square : '—' }}
              </p>
            </div>
            <!--<div class="grid mt-4">
              <p class="mozzarella opacity-48">
                Категория земли
              </p>
              <p class="feta">
                {{
                  formData.mapObject ? formData.mapObject.category || '—' : '—'
                }}
              </p>
            </div>-->
          </template>
        </div>
      </div>
      <div
        class="button_container"
        v-if="formData.active"
      >
        <r-button
          @click="submit"
          :disabled="isLoading || isSave"
          :title="isEditPage ? 'Сохранить' : 'Добавить'"
          width="wide"
        />
      </div>
    </template>

    <message ref="message" />
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { LMarker, LIcon, LGeoJson, LLayerGroup } from 'vue2-leaflet';
  import { featureGroup } from 'leaflet';
import AdminHeader from '../components/AdminHeader';
import SelectSearchInput from '../components/SelectSearchInput';
import RirMap from '../components/RirMap';
import SelectedList from '../components/SelectedList';
import DeleteModal from '../components/DeleteModal';
import CadastralSelectSearchInput from '../components/CadastralSelectSearchInput';
import Api from '../api/Api';
import { getConcatAddress } from '../helpers/utils';
import Message from '../components/Message';
import Loader from '../components/Loader';
import RirMapLeaflet from '../components/RirMapLeaflet';

export default {
  name: 'Contract',
  components: {
    AdminHeader,
    SelectSearchInput,
    RirMap,
    SelectedList,
    CadastralSelectSearchInput,
    Message,
    Loader,
    RirMapLeaflet,
    LMarker,
    LIcon,
    LGeoJson,
    LLayerGroup

  },
  data() {
    return {
      isSave: true,
      flyButton: '',
      isLoading: false,
      initialData: {},
      users: [],
      workerItems: [],
      landPlotItems: [],
      selectedWorker: null,
      contract: {},
      map: null,
      formData: {
        lessee: null,
        persons: [],
        fin: null,
        start: null,
        contractNum: null,
        typeOfLaw: null,
        square: null,
        code: null,
        type: null,
        description: null,
        mapObject: null,
        active: true
      }
    };
  },
  validations: {
    formData: {
      lessee: {
        required
      },
      start: {
        required
      },
      mapObject: {
        required
      },
      persons: {
        required
      },
      typeOfLaw: {
        required
      },
      contractNum: {
        required
      },
      fin: requiredIf(data => data.typeOfLaw === 'RENT')
    }
  },
  computed: {
    mapHasMarker() {
      return !!this.formData.mapObject?.marker;
    },
    canPersonsDelete() {
      return !((this.formData.lessee?.type === 'INDIVID' && this.formData.lessee?.persons?.length < 1)
        || this.formData.lessee?.type === 'PHIS') && this.formData.active;
    },
    orgList() {
      return [];
    },
    isEditPage() {
      return !!this.$route.params.id;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  async activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && await this.setData(this.$route.params.id);
    this.isShowMap = true;
    await this.$store.dispatch('getUsers').then(res => {
      this.users = res;
      if (this.isEditPage) {
        this.formData.lessee = res.find(item => this.formData.lessee.id === item.id);
      }
    });
    await this.$store.dispatch('getRegions').then(res => {
      this.landPlotItems = res;
      if (this.isEditPage) {
        this.formData.mapObject = res.find(item => this.formData.mapObject.id === item.id);
      }
      this.isLoading = false;
    });
    this.$v.$reset();
  },

  deactivated() {
    this.isShowMap = false;
  },
  mounted() {
    if (!this.isEditPage) {
      this.isSave = false;
      this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    }
  },
  methods: {
    initMap(e) {
      this.map = e;
    },
    concatAddress(el) {
      return getConcatAddress(el);
    },
    copyItem(id) {
      const r = this.$router.resolve({
        path: `/contract/${id}`,
        query: {
          copy: true
        }
      });
      window.location.assign(r.href);
    },
    workersChange(workerList) {
      workerList = workerList.map(item => item.id);
      this.workerItems = this.formData?.lessee?.persons.filter(el => !workerList.includes(el.id));
    },
    async onArchive() {
      this.$rir.modal.open(DeleteModal, {
        icon: false,
        title: 'Архивировать договор?',
        subtitle: 'Договор останется в базе данных и будет перенесён в «Архивные»',
        buttons: ['Не архивировать', 'Архивировать'],
        action: this.archive
      });
    },
    /* async onDelete() {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить договор?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: this.delete
      });
    }, */
    archive() {
      new Api().deleteContract(this.$route.params.id).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    changeLandPlot(item) {
      this.$nextTick().then(() => {
        const group = new featureGroup();

        this.map.mapObject.eachLayer(layer => {
          if (layer.feature != undefined) group.addLayer(layer);
        });

        this.map.mapObject.fitBounds(group.getBounds(), { padding: [20, 20] });
      });
      /* this.map.setBounds(window.ymaps.util.bounds.fromPoints(this.formData.mapObject.wgs.coordinates[0]), {
        checkZoomRange: true,
        zoomMargin: 10,
        duration: 300
      }); */
    },
    changeWorker(item) {
      this.selectedWorker = item;
    },
    addWorker() {
      this.formData.persons.push(this.selectedWorker);
      this.workerItems = this.workerItems.filter(el => el.id !== this.selectedWorker.id);
      this.selectedWorker = null;
      this.isChange('persons');
    },
    async setData(id) {
      this.isLoading = true;
      if (id) {
        await new Api().getContractById(id).then(res => {
          this.contract = res;
          this.formData = { ...this.formData, ...res };
          this.formData.persons = this.formData?.lessee?.persons;
          if (this.isCopy) {
            this.formData.active = true;
          }
        });
      }
      this.initialData = JSON.parse(JSON.stringify(this.formData));
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid || document.querySelector('.field_error')) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          id: this.isEditPage ? this.$route.params.id : null,
          ...this.formData,
          active: true,
        };
        let action = this.isEditPage ? 'editContract' : 'addContract';
        if (this.isCopy) {
          data.id = null;
          action = 'addContract';
        }
        await new Api()[action](data).then(res => {
          if (res.error) {
            this.$refs.message.showMessage(res.error, 'error');
          } else if (this.isEditPage && !this.isCopy) {
            window.location.reload();
          } else {
            const r = this.$router.resolve({
              name: 'index',
              query: {
                tab: 'contracts'
              }
            });
            window.location.assign(r.href);
          }
        });
      }
    },
    resetData() {
      for (const prop in this.formData) {
        this.formData[prop] = Array.isArray(this.formData[prop]) ? [] : null;
      }
      this.formData.active = true;
    },
    changeUser(lessee) {
      console.log(lessee);
      this.formData.lessee = lessee;
      this.formData.persons = [];
      this.workerItems = lessee.persons;
      this.isChange('lessee');
      if (lessee.type === 'PHIS') {
        this.formData.persons = lessee.persons;
        this.workerItems = [];
      }
    },
    isChange(field) {
      if (this.initialData[field] === this.formData[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.formData.coordinates = coords;
      }
    },
    onDragMarker(e) {
      this.formData.coordinates = e.get('target').geometry._bounds[0];
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .r-textarea__label {
  color: rgba(4, 21, 62, 0.32);
}

.grid {
  grid-template-columns: 150px auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  align-items: center;
}

::v-deep .r-textarea__textarea:focus {
  background: transparent !important;
}

::v-deep .r-popover__activator .r-select.disabled {
  opacity: .72 !important;
  pointer-events: none !important;
}

::v-deep .r-input.readonly:not(.r-select) {
  opacity: .72 !important;
}
</style>
