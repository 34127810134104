<template>
  <div class="modal_history">
    <div class="flex mb-6">
      <r-date-picker
        class="flex-1 mr-6"
        :is-clear-model="true"
        :click-close="true"
        :period="true"
        label="Дата загрузки"
        v-model="sortDate"
      />
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            code: 'ALL',
            value: 'Все',
          },
          ...this.$store.state.regions.cadastralXMLTypes,
        ]"
        title-value="value"
        id-value="code"
        label="Название файла"
        v-model="selectedXMLTypes"
      />
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            code: 'ALL',
            value: 'Все',
          },
          ...cadastralFileStatuses,
        ]"
        title-value="value"
        id-value="code"
        label="Статус"
        v-model="selectedCadastralFileStatuses"
      />
    </div>
    <r-table
      :headers="headers"
      :items="filteredObjects"
      is-fixed-header
      color-header="amelie"
      :is-loading="loading"
    >
      <template #createdAt="{ item }">
        {{ item['createdAt'] | fullDateTime }}
      </template>

      <template #fileName="{ item }">
        <r-shorter
          :text="item['fileName'] || '—'"
          :count-row="1"
          :tooltip-options="{
            position: 'center-right',
            alignTitle: 'start',
            maxWidth: '300px',
          }"
        />
      </template>
      <template #numberOfAlreadyExistingMapObjects="{ item }">
        {{ item['numberOfAlreadyExistingMapObjects'] }}
      </template>
      <template #numberOfCreatedNewMapObjects="{ item }">
        {{ item['numberOfCreatedNewMapObjects'] }}
      </template>
      <template #numberOfUpdatedMapObjects="{ item }">
        {{ item['numberOfUpdatedMapObjects'] }}
      </template>
      <template #numberOfCreatedDuplicatesOfMapObjects="{ item }">
        <div
          @click="openModalHistoryById(item.id, 'cadastralIdentifiersOfCreatedDuplicatesOfMapObjects')"
          class="pointer sulguni"
        >
          {{ item['numberOfCreatedDuplicatesOfMapObjects'] }}
        </div>
      </template>
      <template #numberOfFailedMapObjects="{ item }">
        <div
          @click="openModalHistoryById(item.id, 'cadastralIdentifiersOfFailedMapObjects')"
          class="pointer sulguni"
        >
          {{ item['numberOfFailedMapObjects'] }}
        </div>
      </template>
      <template #numberOfMapObjectsWithoutCoords="{ item }">
        <div
          @click="openModalHistoryById(item.id, 'cadastralIdentifiersOfMapObjectsWithoutCoords')"
          class="pointer sulguni"
        >
          {{ item['numberOfMapObjectsWithoutCoords'] }}
        </div>
      </template>
    </r-table>
  </div>
</template>

<script>
import Api from '../api/Api';
import ModalHistoryById from './ModalHistoryById';

export default {
  name: 'ModalHistory',
  data: () => ({
    headers: [
      {
        field: 'id',
        name: '№',
        style: {
          width: '50px'
        }
      },
      {
        field: 'createdAt',
        name: 'Дата/время загрузки',
        style: {
          width: '150px'
        },
        slot: true,
        sort: true
      },
      {
        field: 'fileName',
        name: 'Название файла',
        style: {
          width: '200px'
        },
        slot: true,
        sort: true
      },
      {
        field: 'statusLabel',
        name: 'Статус',
        style: {
          width: '100px'
        },
        sort: true
      },
      {
        field: 'numberOfAlreadyExistingMapObjects',
        name: 'Найдено существующих',
        style: {
          width: '160px'
        },
        slot: true
      },
      {
        field: 'numberOfCreatedNewMapObjects',
        name: 'Новых',
        style: {
          width: '80px'
        },
        slot: true
      },
      {
        field: 'numberOfUpdatedMapObjects',
        name: 'Перезаписано',
        style: {
          width: '100px'
        },
        slot: true
      },
      {
        field: 'numberOfCreatedDuplicatesOfMapObjects',
        name: 'Создано дублей',
        style: {
          width: '100px'
        },
        slot: true
      },
      {
        field: 'numberOfFailedMapObjects',
        name: 'Неудачно',
        style: {
          width: '60px'
        },
        slot: true
      },
      {
        field: 'numberOfMapObjectsWithoutCoords',
        name: 'Без координат',
        style: {
          width: '100px'
        },
        slot: true
      }
    ],
    items: [],
    loading: true,
    selectedXMLTypes: 'ALL',
    cadastralXMLTypes: [],
    sortDate: null,
    selectedCadastralFileStatuses: 'ALL',
    cadastralFileStatuses: []
  }),
  computed: {
    filteredObjects() {
      let list = this.items;
      if (this.selectedCadastralFileStatuses !== 'ALL') {
        list = list.filter(el => this.selectedCadastralFileStatuses === el.status);
      }
      if (this.selectedXMLTypes !== 'ALL') {
        list = list.filter(el => this.selectedXMLTypes === el.schemaTypeCode);
      }
      // list = list.sort((a, b) => {
      //   a = a.fileName.toLowerCase();
      //   b = b.fileName.toLowerCase();
      //   return (a === b ? 0 : a > b ? 1 : -1) * (this.selectedSortName === 'ASC' ? 1 : -1);
      // });

      if (this.sortDate) {
        const startDate = new Date(this.sortDate[0]);
        const endDate = new Date(this.sortDate[1]);

        list = list.filter(a => {
          const date = new Date(a.createdAt);
          return (date >= startDate && date <= endDate);
        });
      }
      return list || [];
    }
  },
  async mounted() {
    this.loading = true;
    await new Api().getUploadHistory().then(res => {
      this.items = res;
    }).finally(() => {
      this.loading = false;
    });
    await new Api().getCadastralFileStatuses().then(res => {
      this.cadastralFileStatuses = res;
    });

  },
  methods: {
    openModalHistoryById(id, type) {
      this.$rir.modal.open(ModalHistoryById, {
        id,
        type
      }, {
        isClose: false,
        backText: 'Назад'
      });
    }
  }

};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
