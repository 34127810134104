<template>
  <div>
    <div class="flex mb-6">
      <router-link
        class="mr-6"
        to="user"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-button-action
          icon="add"
          title="Добавить пользователя"
        />
      </router-link>
      <!--<p class="flex align-items-center sulguni color-rocky mr-6">
        <r-icon fill="rocky" size="16"
          class="mr-2"
          icon="add"
        />
        Загрузить данные
      </p>-->
    </div>
    <div class="flex mb-6">
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            id: 'ALL',
            title: 'Все',
          },
          ...$store.state.users.userTypes,
        ]"
        label="Вид деятельности"
        v-model="selectedUserType"
        @input="changeFilter($event, 'selectedUserType')"
      />

      <r-input
        class="flex-1"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />
    </div>
    <loader v-if="isLoading" />
    <div
      class="flex mb-6 align-items-center"
      v-show="!isLoading"
    >
      <div class="ml-auto parmigiano color-titanic opacity-48">
        {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
      </div>
    </div>

    <scroll-table
      v-show="!isLoading"
      :list="filteredObjects"
      :headings="headings"
      :columns-grid="tableGridColumns"
      row-link="/user/"
      class="rows_space-between"
      start-sorted-column="name"
      :delete-function="openDeleteModal"
    />
  </div>
</template>

<script>
import ScrollTable from './Table';
import { wordMatch } from '../helpers/utils';
import DeleteModal from './DeleteModal';
import Api from '../api/Api';
import Loader from './Loader';

export default {
  name: 'TabUsers',
  components: {
    Loader,
    ScrollTable
  },
  data() {
    return {
      selectedUserType: 'ALL',
      searchText: '',
      isShowMap: false,
      // table
      headings: [
        {
          title: 'Наименование',
          field: 'name'
        },
        // 'ИНН',
        {
          title: 'Инн',
          field: 'inn'
        },
        {
          title: 'Фактический адрес',
          field: 'actualAddress'
        },
        {
          title: 'Юридический адрес',
          field: 'legalAddress'
        }
      ],
      tableGridColumns: [
        '256px',
        '120px',
        '220px',
        '220px',
        '40px'
      ]
    };
  },
  computed: {
    filteredObjects() {
      let list = this.$store.getters.getUsersForTable;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.columns?.name?.title || '', this.searchText) <= 0.7
            || wordMatch(el?.columns?.inn || '', this.searchText) <= 0.7
            || wordMatch(el?.columns?.actualAddress || '', this.searchText) <= 0.7
            || wordMatch(el?.columns?.legalAddress || '', this.searchText) <= 0.7
        );
      }
      if (this.selectedUserType !== 'ALL') {
        list = list.filter(el => el.userTypeKey === this.selectedUserType);
      }

      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    isLoading() {
      return this.$store.state.users.usersLoading;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    inputMask: {
      get() {
        return this.cadastrNumber;
      },
      set(val) {
        if (val) {
          const mask = val
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1:$2')
            .replace(/(\d{2})(\d)/, '$1:$2');
          this.cadastrNumber = mask.length ? mask : null;
        }
      }
    }
  },
  async mounted() {
    const setFiltersField = [
      'selectedUserType'
    ];
    setFiltersField.forEach(field => {
      const initialField = this[field];
      this[field] = localStorage.getItem(field) || initialField;
    });
    await this.$store.dispatch('getUsers');
  },
  methods: {
    changeFilter(e, field) {
      localStorage.setItem(field, e);
    },
    openDeleteModal(id) {
      this.$rir.modal.open(DeleteModal, {
        title: 'Удалить пользователя?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: () => { this.delete(id); }
      });
    },
    async delete(id) {
      await new Api().deleteUser(id).then(() => {
        window.location.reload();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.rows_space-between {
  ::v-deep .table_row {
    justify-content: space-between;
  }
}
</style>
