<template>
  <div
    class="table"
    ref="table"
  >
    <scroll-bar
      :options="{ alwaysShowTracks: true }"
      ref="scrollView"
    >
      <div
        class="table_head"
        v-if="headings.length > 0"
        ref="tableHead"
      >
        <div
          class="table_row mozzarella"
          :style="
            !!columnsGrid
              ? 'grid-template-columns:' + columnsGrid.join(' ')
              : 'grid-auto-flow:column;'
          "
        >
          <div
            class="table_col opacity-48 pointer flex align-items-center"
            v-for="(head, index) of headings"
            :key="'head' + index"
            @click="sortingClick(head.field)"
          >
            {{ head[fieldName] }}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.37829 11.835C8.16934 12.055 7.83058 12.055 7.62163 11.835L5.15665 9.96155C4.94771 9.74158 4.94771 9.38494 5.15665 9.16498C5.3656 8.94501 5.70436 8.94501 5.91331 9.16498L7.99996 10.6402L10.0866 9.16497C10.2956 8.94501 10.6343 8.94501 10.8433 9.16497C11.0522 9.38494 11.0522 9.74158 10.8433 9.96154L8.37829 11.835Z"
                :fill="currentSortDir === 'desc' && currentSort === head.field ? '#3D75E4' : '#C0D6F6'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.6217 4.16497C7.83064 3.94501 8.16939 3.94501 8.37832 4.16497L10.8433 6.03846C11.0522 6.25842 11.0522 6.61506 10.8433 6.83503C10.6344 7.05499 10.2956 7.05499 10.0867 6.83503L8.00001 5.35983L5.91332 6.83503C5.70439 7.05499 5.36564 7.05499 5.1567 6.83503C4.94777 6.61506 4.94777 6.25842 5.1567 6.03846L7.6217 4.16497Z"
                :fill="currentSortDir === 'asc' && currentSort === head.field ? '#3D75E4' : '#C0D6F6'"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        class="table_body"
        v-if="filteredList.length"
      >
        <router-link
          :to="rowLink + row.id"
          class="table_row"
          v-for="row of filteredList"
          :key="row.id + 'table_row'"
          :style="
            !!columnsGrid
              ? 'grid-template-columns:' + columnsGrid.join(' ')
              : 'grid-auto-flow:column;'
          "
          :class="{ disabled: !row.active, error: row.endThisMonth && row.active }"
        >
          <div
            class="table_col flex align-items-center feta"
            :key="row.id + 'table_col' + colIndex"
            v-for="(col, colIndex) of row.columns"
          >
            <span
              class="feta overflow"
              :class="{ 'color-fargo': row.endThisMonth && colIndex === 'fin' && row.active }"
              :title="!!col ? col[fieldName] || col : null"
              v-html="(!!col ? col[fieldName] || col : '—') + getSubtitle(col)"
            />
            <r-tooltip
              activator-hover
              color="amelie"
              v-if="row.endThisMonth && colIndex === 'fin' && row.active"
              title="Закончится в этом месяце"
            >
              <template #activator>
                <r-icon
                  size="16"
                  icon="warning"
                  fill="fargo"
                  class="pointer ml-2"
                />
              </template>
            </r-tooltip>
          </div>
          <div
            class="table_col"
            v-if="deleteButton || copyButton || archiveButton"
          >
            <additional-menu>
              <ul
                class="card__menu"
                style="display: grid; grid-gap: 24px;"
              >
                <li
                  v-if="copyButton"
                  class="sulguni flex pointer opacity-72 align-items-center"
                  @click.stop.prevent="copyItem(row.id)"
                >
                  <r-icon
                    fill="rocky"
                    size="16"
                    class="mr-3"
                    icon="copy"
                  />
                  Дублировать
                </li>
                <li
                  v-if="row.active && archiveButton"
                  class="sulguni flex pointer opacity-72 align-items-center"
                  @click.stop.prevent="onArchive(row.id)"
                >
                  <r-icon
                    size="16"
                    class="mr-3"
                    icon="archive"
                    fill="rocky"
                  />
                  Архивировать
                </li>
                <li
                  v-if="deleteButton"
                  class="sulguni flex pointer opacity-72 align-items-center"
                  @click.stop.prevent="onDelete(row.id)"
                >
                  <r-icon
                    size="16"
                    class="mr-3"
                    icon="delete"
                    fill="fargo"
                  />
                  Удалить
                </li>
              </ul>
            </additional-menu>
          </div>
        </router-link>
      </div>
      <not-found v-else />
    </scroll-bar>
  </div>
</template>

<script>
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import AdditionalMenu from './AdditionalMenu';
import DeleteModal from './DeleteModal';
import Api from '../api/Api';
import notFound from './NotFound';

export default {
  name: 'Table',
  components: { ScrollBar, AdditionalMenu, notFound },
  props: {
    headings: {
      type: [Array, Object],
      default: () => []
    },
    list: {
      type: Array,
      required: true
    },
    columnsGrid: {
      type: Array
    },
    rowLink: {
      type: String,
      default: ''
    },
    startSortedColumn: {
      type: String,
      default: ''
    },
    deleteButton: {
      type: Boolean,
      default: false
    },
    copyButton: {
      type: Boolean,
      default: false
    },
    archiveButton: {
      type: Boolean,
      default: false
    },
    deleteFunction: {
      type: Function,
      default: () => {}
    },
    fieldName:{
      type: String,
      default: 'title'
    }
  },
  data() {
    return {
      rows: [],
      currentSort: 'title',
      currentSortDir: 'asc'
    };
  },
  computed: {
    filteredList() {
      const { list } = this.$props;
      if (this.currentSort) {
        list.sort((a, b) => {
          let modifier = 1;
          if (!!a.columns[this.currentSort]?.title && !!b.columns[this.currentSort]?.title) {
            if (this.currentSortDir === 'desc') modifier = -1;
            if (a.columns[this.currentSort].title < b.columns[this.currentSort].title) return -1 * modifier;
            if (a.columns[this.currentSort].title > b.columns[this.currentSort].title) return 1 * modifier;
          } else {
            if (this.currentSortDir === 'desc') modifier = -1;
            if (a.columns[this.currentSort] < b.columns[this.currentSort]) return -1 * modifier;
            if (a.columns[this.currentSort] > b.columns[this.currentSort]) return 1 * modifier;
          }

          return 0;
        });
      }
      return list;
    }
  },
  created() {
    this.currentSort = this.startSortedColumn;
  },
  mounted() {
  },
  methods: {
    sortingClick(field) {
      if (field === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = field;
    },
    getSubtitle(col) {
      if (col?.subtitle === 0 || col?.subtitle === 1) {
        const arr = [
          {
            id: 0,
            value: 'Муниципальная собственность'
          },
          {
            id: 1,
            value: 'Неразграниченная собственность'
          }
        ];
        return `<div class="subtitle mozzarella opacity-48 mt-1">${arr[col?.subtitle].value}</div>`;
      }
      if (col?.subtitle) {
        return `<div class="subtitle mozzarella opacity-48 mt-1">${
          col?.subtitle
        }</div>`;
      }
      return '';
    },
    async onArchive(id) {
      this.$rir.modal.open(DeleteModal, {
        icon: false,
        title: 'Архивировать договор?',
        subtitle: 'Договор останется в базе данных и будет перенесён в «Архивные»',
        buttons: ['Не архивировать', 'Архивировать'],
        action: () => {
          this.archive(id);
        }
      });
    },
    archive(id) {
      new Api().deleteContract(id).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    copyItem(id) {
      this.$router.push({ path: `contract/${id}`, query: { copy: true } });
    },
    async onDelete(id) {
      this.$props.deleteFunction(id);
    },
    sortTable(col) {
      this.sorting *= -1;
      this.sortingType = col;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
  overflow: hidden;

  &_col {

    color: #04153e;

    .overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.r-tooltip {
  position: relative;
}

.r-tooltip__wrapper--arrow {
  position: absolute;
  top: 100%;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  background-color: transparent !important;
}

.r-tooltip__wrapper {
  position: absolute;
  top: calc(100% + 6px);
  border-radius: 12px;
  padding: 4px 8px;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0 4px 16px rgba(48, 3, 14, 0.16));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
}

.table_row {
  display: grid !important;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #e4edfb;
  position: relative;
  width: max-content;
  min-width: 100%;

  &.hidden {
    overflow: hidden;
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f9fe;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
    transition-delay: 0.3s;
    cursor: pointer;
    opacity: 0;

    .r-icon {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      .r-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.table_body {
  width: max-content;
  min-width: 100%;

  .table_row {
    &:hover {
      background: #f6f9fe;
    }

    &.error {
      &:hover {
        background: #fef8f9;

        .r-tooltip__wrapper {
          opacity: 1;
        }
      }

      .r-checkbox {
        pointer-events: none;
      }

      .r-checkbox__input:before {
        border: 2px solid #e14761;
        opacity: 0.32;
      }
    }
  }
}

.table_head {
  width: max-content;
  min-width: 100%;

  .table_row {
    border: none;
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
  //background: red;
}

::v-deep .scrollbar-track-x {
  background: #f6f9fe;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 28px;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}

.r-tooltip {
  &__wrapper {
    background-color: var(--rir-amelie);
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    left: -10px;
    top: calc(100% + 10px);
    max-width: 345px;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;

    &--arrow {
      border-bottom-color: var(--rir-amelie);
      border-top-color: var(--rir-amelie);
      z-index: 100;
      top: -6px;
    }
  }
}

.pb-100px {
  padding-bottom: 100px;
}

::v-deep .c-scroll-view {
  padding-bottom: 185px;
}

.table_row {
  &.disabled {
    .feta {
      opacity: .48;
    }
  }

  &.error {
    background: #FEF8F9;
  }
}
::v-deep .r-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}
</style>
