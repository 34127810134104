<template>
  <scroll-table
    :list="list"
    :headings="$store.state.contracts.headings"
    row-link="/contract/"
    :columns-grid="tableGridColumns"
    start-sorted-column="title"
    :copy-button="true"
    :archive-button="true"
  />
</template>

<script>
import ScrollTable from './Table';

export default {
  name: 'ContractsTable',
  components: {
    ScrollTable
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableGridColumns: [
        '216px',
        '216px',
        '212px',
        '120px',
        '120px',
        '118px',
        '180px',
        '204px',
        '56px',
        '216px',
        '40px'
      ]
    };
  }
};
</script>

<style scoped></style>
