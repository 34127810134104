<template>
  <div>
    <div
      class="staff mt-10"
      v-for="(item, index) in list"
      :key="index"
    >
      <div class="flex">
        <p class="bryndza">
          Сотрудник {{ index + 1 }}
        </p>
        <r-button-action
          v-if="item.delete"
          class="ml-6"
          color="fargo"
          @click="remove(item.id)"
          icon="delete"
          title="Удалить"
        />
        <r-tooltip
          v-else
          activator-hover
          color="amelie"
          title="Сотрудник не может быть удалён, так как прикреплён к договорам"
        >
          <template #activator>
            <r-button-action
              class="ml-6"
              color="fargo"
              icon="delete"
              title="Удалить"
            />
          </template>
        </r-tooltip>
      </div>
      <div class="flex mt-4">
        <r-input
          class="flex-1 mr-6"
          label="ФИО"
          v-model="item.title"
        />
        <r-input
          class="flex-1"
          label="Должность"
          v-model="item.position"
        />
      </div>
      <div class="flex mt-8">
        <div class="flex align-start flex-1 mr-6">
          <div class="flex-1 mr-2">
            <r-input
              label="Телефон"
              @onPressEnter="addPhone(item.id)"
              v-model="item.phone"
              v-mask="'+7 (###) ###-##-##'"
            />
            <selected-list
              v-show="item.phones.length"
              class="mt-4"
              icon="telephone"
              v-model="item.phones"
              text-value=""
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addPhone(item.id)"
            icon="add"
            :disabled="!item.phone"
          />
        </div>
        <div class="flex align-start flex-1">
          <div class="flex-1 mr-2">
            <r-input
              label="Имейл"
              @onPressEnter="addMail(item.id)"
              v-model="item.email"
            />
            <selected-list
              v-show="item.emails.length"
              class="mt-4"
              icon="mail"
              v-model="item.emails"
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addMail(item.id)"
            icon="add"
            :disabled="!item.email"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SelectedList from './SelectedList';
import Api from '../api/Api';

export default {
  name: 'PersonsList',
  components: { SelectedList },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.list = val;
      }
    }
  },
  methods: {
    remove(id) {
      const list = this.list.filter(el => el.id !== id);
      this.list = list;
      this.$emit('input', list);
      new Api().deletePersonUser(id).then(res => {
        console.log(res);
      });
    },
    addPhone(id) {
      const item = this.list.find(el => el.id === id);
      item.phones.push(item.phone);
      item.phone = null;
    },
    addMail(id) {
      const item = this.list.find(el => el.id === id);
      item.emails.push(item.email);
      item.email = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.hovered:hover{
  opacity: 1;
}
</style>
