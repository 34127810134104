  <template>
    <div class="container-upload">
      <r-icon
        v-if="icon"
        icon="warning"
        fill="fargo"
        size="56"
      />
      <h1 class="ricotta mb-4 mt-7">
        {{ title || 'Удалить объект?' }}
      </h1>
      <div class="mb-8 font">
        {{ subtitle || 'Это действие невозможно будет отменить' }}
      </div>
      <div class="mt-2 buttons">
        <r-button
          class="flex-1"
          type="secondary"
          width="wide"
          :title="buttons[0] || 'Не удалять'"
          @click.native="$rir.modal.close()"
        />
        <r-button
          class="flex-1"
          width="wide"
          :title="buttons[1] || 'Не удалять'"
          @click.native.prevent.stop="Delete"
        >
          {{ buttons[1] || 'Да, удалить' }}
        </r-button>
      </div>
    </div>
  </template>

<script>
import Api from '../api/Api';

export default {
  name: 'DeleteModal',
  props: {
    icon: {
      type: Boolean,
      default: true
    },
    action: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => (['Не удалять', 'Да, удалить'])
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    async Delete() {
      this.$props.action();
    }
  }
};
</script>

<style lang="scss" scoped>
.container-upload{
  max-width: 760px;
  margin: 0 auto;
}
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media (max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
</style>
