var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('r-uploader',{attrs:{"disabled":_vm.disabled,"title":_vm.title,"sub-title":_vm.subTitle,"accept":_vm.acceptFile,"callback":_vm.uploadFile,"max-files":_vm.countFile,"button-simple-option":{
      icon: 'delete',
      color: 'fargo',
    },"multiple":""},model:{value:(_vm.filesUpload),callback:function ($$v) {_vm.filesUpload=$$v},expression:"filesUpload"}}),_c('div',{staticClass:"mt-6 flex flex-direction-column"},_vm._l((_vm.value),function(file,index){return _c('r-file',{key:index,staticClass:"mb-3",attrs:{"icon-preview":"file","file":file,"title":file.name,"button-simple-option":{
        icon: 'delete',
        color: 'fargo',
      }},on:{"actions":_vm.deleteFile,"clickPreview":_vm.openPreview}})}),1),_c('message',{ref:"message"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }